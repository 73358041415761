import Loader from "@/components/Loader";
import Button from "@/components/UI/Button";
import Dropdown from "@/components/UI/Dropdown/Dropdown";
import useDebounce from "@/hooks/useDebounce";
import { updateProjectDetails } from "@/services/api";
import { formatNumberWithCommas, removeCommas } from "@/utils/commanFunctions";
import { useEffect, useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import ConfirmationModal from "../ConfirmationModal";
import { defaultState, defaultTypeOfRevenue } from "../DetailConstant";

type DefaultStateKeys = keyof typeof defaultState;

const CustomTooltip = ({ content }: { content: string }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="relative inline-block">
      <FaInfoCircle className="text-gray-400 cursor-pointer ml-1" onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)} />
      {isVisible && (
        <div className="min-w-[200px] absolute z-10 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700 bottom-full left-12 -translate-x-1/2 mb-2 w-48">
          {content}
          <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
      )}
    </div>
  );
};

const LabelWithTooltip = ({ label, tooltip }: { label: string; tooltip: string }) => (
  <div className="label flex items-center">
    {label}
    <CustomTooltip content={tooltip} />
  </div>
);

const FinancingDetails = ({ data, refetch, isInReviewForListing }: any) => {
  const {
    revenueStreams = [],
    symbol = "",
    fundingGoal = "",
    fundingReceived = "",
    tokenPrice = "",
    tokenAmount = "",
    currencyForTokenSale = "",
    currencyForRevenueDistribution = "",
    revenueSharePercentage = "",
    contractTerm = "",
    fixedIntrestRatePercentage = "",
    fundingUsage = [{ financingDescription: "", amount: "", title: "", currency: "" }],
  } = { ...data };

  const [isEditMode, setIsEditMode] = useState({
    financing: false,
  });
  const [details, setDetail] = useState({
    financing: {
      tokenPrice: "",
      tokenAmount: "",
      symbol: "",
      contractTerm: "",
      currencyForTokenSale: "",
      fundingGoal: "",
      currencyForRevenueDistribution: "",
      revenueSharePercentage: 0,
      fixedIntrestRatePercentage: 0,
      fundingReceived: "",
      fundingUsage: [{ financingDescription: "", amount: "", title: "", currency: "" }],
    },
  });
  const [isUpdating, setUpdating] = useState(false);
  // eslint-disable-next-line
  const [typeOfRevenue, setTypeOfRevenue] = useState(defaultTypeOfRevenue);
  // eslint-disable-next-line
  const [rangeValueMin, setRangeValueMin] = useState(0);
  const debouncedQueryMin = useDebounce(rangeValueMin, 500);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    const evt = { target: { name: "fundingReceived", value: debouncedQueryMin } };
    handleChangeDetails(evt, "financing");
    // eslint-disable-next-line
  }, [debouncedQueryMin]);

  useEffect(() => {
    setDetail({
      financing: {
        tokenPrice,
        tokenAmount,
        symbol,
        contractTerm,
        currencyForTokenSale,
        fundingGoal,
        fundingUsage,
        currencyForRevenueDistribution,
        revenueSharePercentage,
        fundingReceived,
        fixedIntrestRatePercentage,
      },
    });

    setTypeOfRevenue((prevState: any) => {
      return prevState.map((item: any) => {
        const hasStream = revenueStreams.find((ele: any) => ele.type === item.value);
        if (hasStream) {
          return {
            ...item,
            name: hasStream.name,
            status: true,
            yearlyOrMonthly: hasStream.monthlyRevenue ? "0" : "1",
            projectedRevenue: hasStream.monthlyRevenue || hasStream.yearlyRevenue,
          };
        }
        return item;
      });
    });
    // eslint-disable-next-line
  }, [data]);

  const handleChangeDetails = async (evt: any, changingTo: any, isFundingUpdateIndex?: number) => {
    const { name, value } = evt.target;

    // List of names to check against
    const namesToCheck = [
      "operationLifetime",
      "totalInitialCost",
      "annualOperationCosts",
      "npv",
      "irr",
      "lcoe",
      "paybackPeriod",
      "capacityFactor",
      "fundingGoal",
      "tokenPrice",
      "tokenAmount",
      "contractTerm",
      "amount",
    ];

    // Replace commas only if the name is in the list
    const processedValue = namesToCheck.includes(name) && typeof value === "string" ? removeCommas(value) : value;

    if (name === "fundingGoal") {
      const calculatedTokenAmount = calculateTokenAmount(processedValue); // Function to calculate token amount
      setDetail((prevState: any) => ({
        ...prevState,
        [changingTo]: {
          ...prevState[changingTo],
          fundingGoal: processedValue,
          tokenAmount: calculatedTokenAmount, // Update tokenAmount based on new fundingGoal
        },
      }));
    } else {
      if (!isUpdating) {
        if (isFundingUpdateIndex !== undefined) {
          setDetail((prevState: any) => {
            let newUpdatedValue = [...prevState.financing.fundingUsage];
            newUpdatedValue[isFundingUpdateIndex] = { ...newUpdatedValue[isFundingUpdateIndex], [name]: processedValue };
            return {
              ...prevState,
              [changingTo]: {
                ...prevState[changingTo],
                fundingUsage: newUpdatedValue,
              },
            };
          });
          return;
        }
        if (changingTo === "assetIds") {
          setDetail((prevState: any) => {
            return {
              ...prevState,
              [changingTo]: [
                {
                  ...prevState[changingTo][0],
                  [name]: processedValue,
                },
              ],
            };
          });
          return;
        }
        setDetail((prevState: any) => {
          return {
            ...prevState,
            [changingTo]: {
              ...prevState[changingTo],
              [name]: processedValue,
            },
          };
        });
      }
    }
  };

  const handleDeleteFundingUsage = (clickedIndex: number) => {
    setDetail((prevState: any) => {
      let newList = [...prevState.financing.fundingUsage];
      newList.splice(clickedIndex, 1);
      return {
        ...prevState,
        financing: {
          ...prevState.financing,
          fundingUsage: newList,
        },
      };
    });
  };

  const handleAddFundingUsage = () => {
    setDetail((prevState: any) => {
      return {
        ...prevState,
        financing: {
          ...prevState.financing,
          fundingUsage: [...prevState.financing.fundingUsage, { financingDescription: "", amount: "", title: "", isNew: true }],
        },
      };
    });
  };

  // eslint-disable-next-line
  const handleRevenueChange = (value: number) => {
    setDetail((prevDetails): any => ({
      ...prevDetails,
      financing: {
        ...prevDetails.financing,
        revenueSharePercentage: value,
      },
    }));
  };

  // eslint-disable-next-line
  const handleIntrestChange = (value: number) => {
    setDetail((prevDetails): any => ({
      ...prevDetails,
      financing: {
        ...prevDetails.financing,
        fixedIntrestRatePercentage: value,
      },
    }));
  };

  const handleUpdateDetails = async () => {
    setUpdating(true);
    const updatedDetails = { ...data, ...details["financing"] };
    await updateProjectDetails(data._id, updatedDetails);
    await refetch();
    setUpdating(false);
    setShowConfirmModal(false);
  };

  const handleClickCancleOrEdit = async (clickedTo: DefaultStateKeys) => {
    setIsEditMode((prevState: any) => ({ ...prevState, [clickedTo]: !prevState[clickedTo] }));

    const financing = {
      tokenPrice,
      tokenAmount,
      symbol,
      contractTerm,
      currencyForTokenSale,
      revenueSharePercentage,
      fundingUsage,
      currencyForRevenueDistribution,
      fundingGoal,
      fundingReceived,
      fixedIntrestRatePercentage,
    };

    setDetail((prevState: any) => {
      return {
        ...prevState,
        [clickedTo]: {
          ...(clickedTo === "financing" ? financing : data[clickedTo] ? data[clickedTo] : defaultState[clickedTo]),
        },
      };
    });
    setTypeOfRevenue(() => {
      return defaultTypeOfRevenue.map((item: any) => {
        const hasStream = revenueStreams.find((ele: any) => ele.type === item.value);
        if (hasStream) {
          return {
            ...item,
            name: hasStream.name,
            status: true,
            yearlyOrMonthly: hasStream.monthlyRevenue ? "0" : "1",
            projectedRevenue: hasStream.monthlyRevenue || hasStream.yearlyRevenue,
          };
        }
        return { ...item, status: false };
      });
    });
  };

  const handleSaveClick = () => {
    setShowConfirmModal(true);
  };

  // Function to calculate token amount based on funding goal
  const calculateTokenAmount = (fundingGoal: string) => {
    // Implement your logic for calculating token amount based on the funding goal
    const goalValue = parseFloat(fundingGoal?.replace(/[$,]/g, "")) / 500; // Remove $ and commas for calculation
    // Example logic: Assuming 1 token equals $1, adjust as necessary
    return goalValue; // Replace with your calculation
  };

  return (
    <>
      <div className="general-card-section">
        <h2 className="text-[14px]">Financing</h2>
        <p className="text-[11px] ">Tokenization & Fundraising</p>
      </div>
      <div className="vertical-line"></div>
      <div className="general-card-section text-[11px]">
        <div className="flex flex-wrap items-center justify-between">
          <div className="field w-[100%] sm:w-[48%]">
            <div className="relative">
              {/* <div className="label">Capital needed</div>
               */}
              <LabelWithTooltip label="Capital needed" tooltip="The amount you want to raise" />

              <input
                disabled={!isEditMode.financing}
                value={formatNumberWithCommas(String(details.financing.fundingGoal))}
                type="text"
                name="fundingGoal"
                onChange={(evt) => handleChangeDetails(evt, "financing")}
                placeholder=""
                className={`${
                  isEditMode.financing ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                } px-[20px] w-full text-ternary text-sm`}
              />
              <p className={`absolute bottom-2 left-2 text-monochrome-60`}>$</p>
              <p className={`absolute bottom-2 right-2 text-monochrome-60`}>USD</p>
            </div>
          </div>
          <div className="field w-[100%] sm:w-[48%]">
            {/* <div className="label">Token symbol</div> */}
            <LabelWithTooltip label="Token symbol" tooltip="The unique identifier for your project's token" />
            <input
              disabled
              value={details.financing.symbol}
              type="text"
              name="symbol"
              onChange={(evt) => handleChangeDetails(evt, "financing")}
              placeholder="BOSHC-SOLAR-1"
              className={`${
                isEditMode.financing ? "border border-monochrome-10 rounded px-2 cursor-no-drop" : "border-none !rounded-none"
              } px-0 w-full text-ternary text-sm`}
            />
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-between">
          <div className="field w-[100%] sm:w-[48%]">
            <div className="relative">
              {/* <div className="label">Token price</div> */}
              <LabelWithTooltip label="Token price" tooltip="The price at which each token is being sold" />
              <input
                disabled
                value={formatNumberWithCommas(String(details.financing.tokenPrice ?? 0))}
                type="text"
                name="tokenPrice"
                onChange={(evt) => handleChangeDetails(evt, "financing")}
                placeholder=""
                className={`${
                  isEditMode.financing ? "border border-monochrome-10 rounded px-2 cursor-no-drop" : "border-none !rounded-none"
                } px-[20px] w-full text-gray-700 text-sm`}
              />
              <p className={`absolute bottom-2 left-2 text-monochrome-60`}>$</p>
              <p className={`absolute bottom-2 right-2 text-monochrome-60`}>USD</p>
            </div>
          </div>
          <div className="field w-[100%] sm:w-[48%]">
            {/* <div className="label">Token amount</div> */}
            <LabelWithTooltip label="Token amount" tooltip="The total number of tokens available for sale" />
            <input
              disabled
              value={formatNumberWithCommas(String(details.financing.tokenAmount ?? 0))}
              type="text"
              name="tokenAmount"
              onChange={(evt) => handleChangeDetails(evt, "financing")}
              placeholder="40266"
              className={`${
                isEditMode.financing ? "border border-monochrome-10 rounded px-2 cursor-no-drop" : "border-none !rounded-none"
              } px-0 w-full text-gray-700 text-sm`}
            />
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-between">
          {/* <div className="field w-[100%] sm:w-[48%]">
              <div className="label">Currency for token sale</div>
              <Dropdown
                menuBoxMarginTop={5}
                disabled={!isEditMode.financing}
                value={details.financing.currencyForTokenSale}
                className={`${
                  isEditMode.financing ? "border rounded px-2" : "border-t-0 border-l-0 border-r-0 border-b border-monochrome-10 !rounded-none"
                } px-0 w-full text-ternary text-sm`}
                onChange={(selectedValue: any) => {
                  const evt = { target: { name: "currencyForTokenSale", value: selectedValue.value } };
                  handleChangeDetails(evt, "financing");
                }}
                placeholder="USD"
                options={[
                  { value: "USD", label: "USD" },
                  { value: "EUR", label: "EUR" },
                  { value: "INR", label: "INR" },
                ]}
              />
            </div>
            <div className="field w-[100%] sm:w-[48%]">
              <div className="label">Currency for revenue distribution</div>
              <Dropdown
                menuBoxMarginTop={5}
                disabled={!isEditMode.financing}
                value={details.financing.currencyForRevenueDistribution}
                className={`${
                  isEditMode.financing ? "border rounded px-2" : "border-t-0 border-l-0 border-r-0 border-b border-monochrome-10 !rounded-none"
                } px-0 w-full text-ternary text-sm`}
                onChange={(selectedValue: any) => {
                  const evt = { target: { name: "currencyForRevenueDistribution", value: selectedValue.value } };
                  handleChangeDetails(evt, "financing");
                }}
                placeholder="USD"
                options={[
                  { value: "USD", label: "USD" },
                  { value: "EUR", label: "EUR" },
                  { value: "INR", label: "INR" },
                ]}
              />
            </div> */}
        </div>
        <div className="flex flex-wrap items-center justify-between">
          <div className="field w-[100%] sm:w-[48%]">
            {/* <div className="label">Revenue share percentage (%)</div> */}
            {/* <LabelWithTooltip label="Revenue share percentage (%)" tooltip="The percentage of revenue that will be shared with token holders" />
          {isEditMode.financing ? (
            <div className="w-full mt-5">
              <SingleRangePicker value={details.financing.revenueSharePercentage || 0} minRange={0} maxRange={100} onChange={handleRevenueChange} />
            </div>
          ) : (
            <input
              disabled
              value={`${details.financing.revenueSharePercentage || 0}%`}
              type="text"
              name="revenuePercentageRangeMaxAndMin"
              onChange={(evt) => handleChangeDetails(evt, "financing")}
              placeholder=""
              className={`${isEditMode.financing ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
            />
          )} */}
            <LabelWithTooltip label="Revenue share percentage (%)" tooltip="The percentage of revenue that will be shared with token holders" />
            <input
              disabled={!isEditMode.financing}
              value={formatNumberWithCommas(String(details.financing.revenueSharePercentage ?? 0))}
              type="number"
              step="0.1"
              name="revenueSharePercentage"
              onChange={(evt) => handleChangeDetails(evt, "financing")}
              placeholder=""
              min="0"
              max="100"
              className={`${isEditMode.financing ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-gray-700 text-sm`}
            />
          </div>

          <div className="field w-[100%] sm:w-[48%]">
            {/* <div className="lab
            el">Contract term (month)</div> */}
            <LabelWithTooltip label="Contract term (month)" tooltip="The duration of the revenue sharing agreement in months" />
            <input
              disabled={!isEditMode.financing}
              value={formatNumberWithCommas(String(details.financing.contractTerm))}
              type="text"
              name="contractTerm"
              onChange={(evt) => handleChangeDetails(evt, "financing")}
              placeholder="Minimum contract term is 1 month"
              className={`${isEditMode.financing ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
            />
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-between">
          <div className="field w-[100%] sm:w-[48%]">
            {/* <div className="label">Fixed intrest rate (%)</div> */}
            {/* <LabelWithTooltip label="Fixed interest rate (%)" tooltip="The guaranteed annual return rate for token holders" />
          {isEditMode.financing ? (
            <div className="w-full mt-5">
              <SingleRangePicker value={details.financing.fixedIntrestRatePercentage || 0} minRange={0} maxRange={100} onChange={handleIntrestChange} />
            </div>
          ) : (
            <input
              disabled
              value={`${details.financing.fixedIntrestRatePercentage || 0}%`}
              type="text"
              name="fixedIntrestRatePercentage"
              onChange={(evt) => handleChangeDetails(evt, "financing")}
              placeholder=""
              className={`${isEditMode.financing ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
            />
          )} */}
            <LabelWithTooltip label="Fixed interest rate (%)" tooltip="The guaranteed annual return rate for token holders" />
            <input
              disabled={!isEditMode.financing}
              value={formatNumberWithCommas(String(details.financing.fixedIntrestRatePercentage ?? 0))}
              type="number"
              step="0.1"
              name="fixedIntrestRatePercentage"
              onChange={(evt) => handleChangeDetails(evt, "financing")}
              placeholder=""
              min="0"
              max="100"
              className={`${isEditMode.financing ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-gray-700 text-sm`}
            />
          </div>
        </div>
        {!isEditMode.financing && (
          <>
            {/* <div className="label mb-4">Funding usage</div> */}
            <LabelWithTooltip label="Funding usage" tooltip="Breakdown of how the raised funds will be utilized" />
            {details.financing.fundingUsage.map((item: any, index: number) => (
              <div className="flex flex-wrap items-center justify-between" key={index}>
                <div className="field w-[30%]">
                  {/* <div className="label">Title</div> */}
                  <LabelWithTooltip label="Title" tooltip="A short name for this funding allocation" />
                  <input
                    disabled={!isEditMode.financing}
                    value={item.title}
                    name="title"
                    onChange={(evt) => handleChangeDetails(evt, "financing")}
                    type="text"
                    placeholder="Operations"
                    className={`${
                      isEditMode.financing ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                    } px-0 w-full text-ternary text-sm `}
                  />
                </div>
                <div className="field w-[30%]">
                  {/* <div className="label">Amount</div> */}
                  <LabelWithTooltip label="Amount" tooltip="The amount allocated for this specific use" />
                  <input
                    disabled={!isEditMode.financing}
                    value={`${formatNumberWithCommas(String(item.amount))} ${item.currency}`}
                    type="text"
                    name="amount"
                    onChange={(evt) => handleChangeDetails(evt, "financing")}
                    placeholder="1,300,000 USD"
                    className={`${
                      isEditMode.financing ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                    } px-0 w-full text-ternary text-sm`}
                  />
                </div>
                <div className="field w-[30%]">
                  {/* <div className="label">Description</div> */}
                  <LabelWithTooltip label="Description" tooltip="Detailed explanation of how this allocation will be used" />
                  <input
                    disabled={!isEditMode.financing}
                    value={item.financingDescription}
                    type="text"
                    name="financingDescription"
                    onChange={(evt) => handleChangeDetails(evt, "financing")}
                    placeholder="For year 1 operations, including maintenance"
                    className={`${
                      isEditMode.financing ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                    } px-0 w-full text-ternary text-sm`}
                  />
                </div>
              </div>
            ))}
          </>
        )}
        {isEditMode.financing && (
          <>
            {details.financing.fundingUsage.map((item: any, index: number) => (
              <div className="max-w-2xl p-6 bg-white shadow rounded-lg mb-5" key={index}>
                <div className="flex justify-between items-center mb-6">
                  {/* <div className="label mb-4">Funding usage {index + 1}</div> */}
                  <LabelWithTooltip label={`Funding usage ${index + 1}`} tooltip="Enter how will you use the funds that are generated" />
                  {item.isNew && (
                    <button className="text-gray-500 hover:text-ternary" onClick={() => handleDeleteFundingUsage(index)}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  )}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                  <div>
                    {/* <div className="label">Title</div> */}
                    <LabelWithTooltip label={`Title`} tooltip="Enter the fund usage title" />
                    <input
                      name="title"
                      type="text"
                      value={item.title}
                      onChange={(evt) => handleChangeDetails(evt, "financing", index)}
                      placeholder="Enter the title"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                  <div>
                    {/* <div className="label">Amount</div> */}
                    <LabelWithTooltip label={`Amount`} tooltip="Enter the Amount usage for this funding" />
                    <div className="flex mt-1">
                      <input
                        name="amount"
                        value={formatNumberWithCommas(String(item.amount))}
                        onChange={(evt) => handleChangeDetails(evt, "financing", index)}
                        type="text"
                        placeholder="Enter the amount"
                        className="block w-full rounded-l-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                      <Dropdown
                        menuBoxMarginTop={5}
                        value={item.currency}
                        className={`border rounded px-2 w-full text-ternary text-sm mx-2`}
                        onChange={(selectedValue: any) => {
                          const evt = { target: { name: "currency", value: selectedValue.value } };
                          handleChangeDetails(evt, "financing", index);
                        }}
                        options={[
                          { value: "USD", label: "USD" },
                          { value: "EUR", label: "EUR" },
                          { value: "INR", label: "INR" },
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  {/* <div className="label">Description</div> */}
                  <LabelWithTooltip label={`Description`} tooltip="Enter the description for usage of this fund" />
                  <textarea
                    value={item.financingDescription}
                    onChange={(evt) => handleChangeDetails(evt, "financing", index)}
                    name="financingDescription"
                    placeholder="Please provide the description"
                    className="placeholder-sm !text-[11px] mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    rows={4}
                  ></textarea>
                </div>
              </div>
            ))}
            <h4 className="text-center mb-5 text-sm text-green-100 cursor-pointer underline" onClick={() => handleAddFundingUsage()}>
              + Add funding usage
            </h4>
          </>
        )}
        <div className="flex gap-4 justify-end">
          {!isInReviewForListing && (
            <Button primary rounded type="button" classNames="h-10" onClick={() => handleClickCancleOrEdit("financing")}>
              {isEditMode.financing ? "Cancel" : "Edit"}
            </Button>
          )}
          {isEditMode.financing && (
            <Button disabled={isUpdating} secondary rounded classNames={`h-10`} onClick={handleSaveClick}>
              {isUpdating ? <Loader isLoading={true} /> : "Save"}
            </Button>
          )}
        </div>
      </div>

      <ConfirmationModal
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleUpdateDetails}
        message="Are you sure you want to update the financing details of your project?"
      />
    </>
  );
};

export default FinancingDetails;
