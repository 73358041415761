import { IcMergeCopy } from "@/assets";
import IcBuy from "@/assets/ic_buy";
import IcSell from "@/assets/ic_sell";
import IcCross from "@/assets/Icons/ic_cross";
import IcDropdownChecked from "@/assets/Icons/ic_dropdown_checked";
import IcPending from "@/assets/Icons/ic_pending";
import { numberWithCommas } from "@/constants/extensions";
import { useNetwork } from "@/provider/NetworkContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import Web3 from "web3";

dayjs.extend(customParseFormat);

const TransactionDetailSideView = ({ transaction, onClose, currentUserId, currentWalletAddress }: any) => {
  const { selectedNetwork } = useNetwork();

  const explorer =
    selectedNetwork === "polygon"
      ? `${process.env.REACT_APP_POLYGONSCAN_TRANSACTION_URL}${transaction.transactionHash}`
      : `${process.env.REACT_APP_PEAQ_SUBSCAN_TRANSACTION_URL}${transaction.transactionHash}`;

  const showCopiedToast = (message: string) => {
    toast.success(message);
  };

  const parsedDate = dayjs(transaction.updatedAt);
  // eslint-disable-next-line
  const tokenAmountFormatted = Web3.utils.fromWei(Web3.utils.toWei(transaction.paymentTokensTransferred?.toString(), "ether"), "ether");

  const isOnChain = transaction.transactionMethod === "ON_CHAIN";

  // Determine if the transaction is incoming or outgoing for the current user
  const isIncomingTransaction = () => {
    if (isOnChain) {
      return transaction.toAddress?.toLowerCase() === currentWalletAddress.toLowerCase();
    } else {
      return transaction.toUserId?._id === currentUserId;
    }
  };

  const isOutgoingTransaction = () => {
    if (isOnChain) {
      return transaction.fromAddress?.toLowerCase() === currentWalletAddress.toLowerCase();
    } else {
      return transaction.fromUserId?._id === currentUserId;
    }
  };

  // eslint-disable-next-line
  const transactionType = isIncomingTransaction() ? "Buy" : "Sell";
  const transactionColor = isIncomingTransaction() ? "text-green-500" : isOutgoingTransaction() ? "text-red-600" : "text-gray-400";
  const transactionSign = isIncomingTransaction() ? "+" : isOutgoingTransaction() ? "-" : "";

  let statusIcon;
  let statusTextColor = "";

  switch (transaction.status) {
    case "FAILED":
      statusIcon = <IcCross className="!text-red-500 transform scale-125 p-2" />;
      statusTextColor = "text-red-500";
      break;
    case "COMPLETED":
      statusIcon = <IcDropdownChecked className="h-[24px] w-[24px] !text-green-500" />;
      statusTextColor = "text-green-500";
      break;
    case "PENDING":
      statusIcon = <IcPending className="!text-yellow-300 transform scale-125 p-2 font-bold" />;
      statusTextColor = "text-yellow-300";
      break;
    default:
      statusIcon = null;
      statusTextColor = "";
  }

  let transactionName;
  switch (transaction.transactionPurpose) {
    case "SECURITY_TOKEN_PURCHASE":
      transactionName = "Security Token Purchase";
      break;
    case "DIVIDEND_PAYMENT":
      transactionName = "Dividend Payment";
      break;
    case "SECURITY_TOKEN_SALE":
      transactionName = "Sell Token";
      break;
    case "SECURITY_TOKEN_TRANSFER":
      transactionName = "Security Token Transfer";
      break;
    case "PAYMENT_TOKEN_TOP_UP":
      transactionName = "Top Up";
      break;
    case "PAYMENT_TOKEN_WITHDRAW":
      transactionName = "Withdraw";
      break;
    default:
      transactionName = "Unknown Transaction";
  }

  // Render bank details if OFF_CHAIN
  const renderBankDetails = (transaction: any) => {
    const fromUserBankDetails = isOutgoingTransaction()
      ? transaction.fromUserId?.companyId 
      : transaction.fromUserId?.investorId;
  
    const toUserBankDetails = isOutgoingTransaction()
      ? transaction.toUserId?.investorId 
      : transaction.toUserId?.companyId; 
  
    if (transaction.transactionMethod === "OFF_CHAIN") {
      return (
        <div className="flex gap-2 flex-col text-sm space-y-2">
          <span className="text-monochrome-20">
            <p>Account Holder Name:</p>
            <p>From:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
              {fromUserBankDetails?.bankAccountHolderName || "Not Available"}
            </p>
            <p>To:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
              {toUserBankDetails?.bankAccountHolderName || "Not Available"}
            </p>
          </span>
          <span className="text-monochrome-20">
            Bank Name:
            <p>From:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
              {fromUserBankDetails?.bankName || "Not available"}
            </p>
            <p>To:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
              {toUserBankDetails?.bankName || "Not available"}
            </p>
          </span>
          <span className="text-monochrome-20">
            Account Number:
            <p>From:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
              {fromUserBankDetails?.bankAccountNumber || "Not available"}
            </p>
            <p>To:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
              {toUserBankDetails?.bankAccountNumber || "Not available"}
            </p>
          </span>
          <span className="text-monochrome-20">
            SWIFT/BIC Code:
            <p>From:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
              {fromUserBankDetails?.bankSwiftBicCode || "Not available"}
            </p>
            <p>To:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
              {toUserBankDetails?.bankSwiftBicCode || "Not available"}
            </p>
          </span>
          <span className="text-monochrome-20">
            IBAN:
            <p>From:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
              {fromUserBankDetails?.bankIban || "Not available"}
            </p>
            <p>To:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
              {toUserBankDetails?.bankIban || "Not available"}
            </p>
          </span>
          <span className="text-monochrome-20">
            Default Currency:
            <p>From:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
              {fromUserBankDetails?.bankDefaultCurrency || "Not available"}
            </p>
            <p>To:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
              {toUserBankDetails?.bankDefaultCurrency || "Not available"}
            </p>
          </span>
          <span className="text-monochrome-20">
            Company Name:
            <p>From:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
              {fromUserBankDetails?.companyName || "Not available"}
            </p>
            <p>To:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
              {toUserBankDetails?.companyName || "Not available"}
            </p>
          </span>
        </div>
      );
    }
    return null;
  };

  // Render transaction details
  const renderTransactionDetails = () => {
    switch (transaction.transactionMethod) {
      case "OFF_CHAIN":
        switch (transaction.transactionPurpose) {
          case "SECURITY_TOKEN_TRANSFER":
          case "SECURITY_TOKEN_PURCHASE":
            return (
              <>
                <div className="flex gap-2 flex-col text-sm">
                  {renderBankDetails(transaction)}
                  <span className="text-monochrome-20">
                    Security Tokens:{" "}
                    <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">{transaction.securityTokensTransferred}</p>
                  </span>
                </div>
              </>
            );
          case "DIVIDEND_PAYMENT":
            return (
              <>
                <div className="flex gap-2 flex-col text-sm">{renderBankDetails(transaction)}</div>
              </>
            );
          default:
            return <span>Unsupported transaction type.</span>;
        }

      case "ON_CHAIN":
        switch (transaction.transactionPurpose) {
          case "PAYMENT_TOKEN_TOP_UP":
          case "PAYMENT_TOKEN_WITHDRAW":
            return (
              <div className="flex gap-5 flex-col text-sm">
                <div className="flex gap-2 flex-col text-sm">
                  <span className="text-monochrome-20">Transaction hash</span>
                  <div className="flex flex-row gap-2 items-center">
                    <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
                      {transaction.transactionHash.length > 10 ? `${transaction.transactionHash.slice(0, 22)}...` : transaction.transactionHash}{" "}
                    </p>
                    <CopyToClipboard text={transaction.transactionHash} onCopy={() => showCopiedToast("Transaction hash copied to clipboard!")}>
                      <IcMergeCopy className={"cursor-pointer"} />
                    </CopyToClipboard>
                  </div>
                </div>
                <div className="flex gap-2 flex-col text-sm">
                  <span className="text-monochrome-20">From</span>
                  <div className="flex flex-row gap-2 items-center">
                    <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
                      {transaction.fromAddress.length > 10 ? `${transaction.fromAddress.slice(0, 22)}...` : transaction.fromAddress}{" "}
                    </p>
                    <CopyToClipboard text={transaction.fromAddress} onCopy={() => showCopiedToast("From address copied to clipboard!")}>
                      <IcMergeCopy className={"cursor-pointer"} />
                    </CopyToClipboard>
                  </div>
                </div>
                <div className="flex gap-2 flex-col text-sm">
                  <span className="text-monochrome-20">To</span>
                  <div className="flex flex-row gap-2 items-center">
                    <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
                      {transaction.toAddress.length > 10 ? `${transaction.toAddress.slice(0, 22)}...` : transaction.toAddress}{" "}
                    </p>
                    <CopyToClipboard text={transaction.toAddress} onCopy={() => showCopiedToast("To address copied to clipboard!")}>
                      <IcMergeCopy className={"cursor-pointer"} />
                    </CopyToClipboard>
                  </div>
                </div>
                <div className="flex gap-2 flex-col text-sm">
                  <span className="text-monochrome-20">Block Explorer</span>
                  <div className="flex flex-row gap-2 items-center">
                    <a
                      href={`${explorer}`}
                      target="_blank"
                      rel="noreferrer"
                      className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100"
                    >{`${explorer}`}</a>
                  </div>
                </div>
              </div>
            );
          default:
            return <span>Unsupported transaction type.</span>;
        }

      default:
        return <span>Transaction method not recognized.</span>;
    }
  };

  return (
    <div className="z-50 bg-white flex flex-col p-4 gap-8 fixed right-0 top-0 w-full max-w-full lg:max-w-[280px] h-screen overflow-y-auto">
      {/* <div className="z-50 bg-white flex flex-col p-4 gap-8 absolute right-0 top-0 w-full max-w-full h-screen lg:relative lg:max-w-[280px]"> */}
      <div className="flex justify-between items-center">
        <h3>Transaction Details</h3>
        <IcCross className={"p-1 bg-monochrome-60 border border-monochrome-40 rounded-lg cursor-pointer"} onClick={onClose} />
      </div>
      <div className="flex gap-2 flex-col text-sm">
        <span className={`flex items-center`}>
          {isIncomingTransaction() ? <IcBuy className={`mr-2 ${transactionColor}`} /> : <IcSell className={`mr-2 ${transactionColor}`} />}
          <span>{transactionName}</span>
        </span>
        <h3 className={`${transactionColor}`}>
          {transactionSign}${numberWithCommas(Math.abs(transaction.paymentTokensTransferred).toFixed(2)) + " "}
          {transaction.paymentTokenName}
        </h3>
      </div>

      <div className={`flex flex-col gap-2 text-sm ${statusTextColor}`}>
        <span className="text-monochrome-20">Status</span>
        <div className="flex items-center">
          {statusIcon}
          <span className={`capitalize ${statusTextColor}`}>{transaction.status.toLowerCase()}</span>
        </div>
      </div>
      <div className="flex gap-2 flex-col text-sm">
        <span className="text-monochrome-20">Time</span>
        <span>{parsedDate.format("hh:mm:ss, MMM DD, YYYY")}</span>
      </div>
      <div className="flex gap-2 flex-col text-sm">
        <span className="text-monochrome-20">Symbol</span>
        <span>{transaction.securityTokenName}</span>
      </div>
      {renderTransactionDetails()}
    </div>
  );
};

export default TransactionDetailSideView;
