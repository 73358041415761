import Loader from "@/components/Loader";
import useAuth from "@/hooks/useAuth";
import { fetchProjects } from "@/services/api";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from 'react';
import { Link, useNavigate } from "react-router-dom";
import ProjectDashboardItem from "./Item";

const ProjectDashboardCard = () => {
    const { companyId } = useAuth();
    const queryClient = useQueryClient();

    const { data: projects, isLoading } = useQuery({
        queryKey: ["getProjects", companyId],
        queryFn: () => fetchProjects(companyId),
        enabled: !!companyId,
    });

    const navigate = useNavigate();
    // const handleRedirectProjects = () => {
    //     navigate("/projects");
    // };

    // Function to sort projects by createdAt in descending order
    const sortedProjects = useMemo(() => {
        if (projects?.data && projects.data.length > 0) {
            return projects.data.slice().sort((a: any, b: any) => {
                return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            });
        }
        return [];
    }, [projects]);

    // Handler to update the project name in the parent component
    const handleUpdateProject = (id: string, newName: string) => {
        // Logic to update project name in state or refetch projects
        queryClient.invalidateQueries('getProjects'); // Invalidate the projects query to refetch the data
    };

    const handleClickProject = (clickedProject: any) => {
        navigate(`/projects/overview?projectId=${clickedProject._id}`)
      };

    return (
        <div className="bg-white p-4 flex flex-col rounded-lg">
            <div className="flex justify-between items-center mb-4">
                <h5 className="font-medium text-monochrome-100 text-xl">Projects</h5>
                <Link className="text-monochrome-50 justify-end body-regular pr-0" to="/projects">View All</Link>
            </div>
            <div className="overflow-x-auto sm:overflow-hidden">
                <div className="w-[150%] sm:w-full">
                    <div className="grid grid-cols-4 text-monochrome-30 body-small-regular py-3 border-b border-monochrome-10">
                        <span className="col-span-1">Name</span>
                        <span className="text-end">Received</span>
                        <span className="text-end">Goal</span>
                        <span className="text-end">Status</span>
                    </div>
                    {
                        isLoading && <div className="center min-h-20">
                            <Loader isLoading={true} />
                        </div>
                    }
                    <div className="max-h-[calc(5*3.5rem)] overflow-y-auto">
                        {
                            sortedProjects.map((project: any, index: number) => (
                                <ProjectDashboardItem
                                    key={`project-item-${index}`}
                                    item={project}
                                    onUpdateProject={handleUpdateProject}
                                    handleClickProject={handleClickProject}
                                />
                            ))
                        }
                    </div>
                    {
                        !isLoading && (!projects || !projects.data || projects.data.length === 0) && (
                            <div className="center min-h-40">
                                <h6 className="font-normal text-[0.75rem]">No projects available.</h6>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default ProjectDashboardCard;
