import IcCross from "@/assets/ic_cross";
import { IcPercentage } from "@/assets/Icons";
import { useNetwork } from "@/provider/NetworkContext";
import { fetchAllNotifications, readNotification } from "@/services/api";
import useStore from "@/store/useStore";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "./Loader";
import Button from "./UI/Button";

const NotificationCard = ({ onClose }) => {
  const navigate = useNavigate();
  const { userData } = useStore();
  const { showAllNotification, setShowAllNotifications } = useNetwork();
  const queryClient = useQueryClient();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  // eslint-disable-next-line
  const [fadeIn, setFadeIn] = useState(false);
  const location = useLocation();
  const [charLimit, setCharLimit] = useState(130);

  const { data: notifications = [], refetch } = useQuery({
    queryKey: ["getNotifications", userData?._id],
    queryFn: () => fetchAllNotifications(userData?._id),
    enabled: !!userData?._id,
    select: (data) => data.data,
    refetchOnWindowFocus: true,
  });

  const sortedNotifications = notifications.sort((a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix());

  const handleDeleteClick = async (notificationId) => {
    setDeleteLoading(true);
    try {
      await readNotification(notificationId);
      await refetch();
      queryClient.invalidateQueries(["getNotifications"]);
    } catch (error) {
      console.error("Error deleting notification:", error);
    } finally {
      setDeleteLoading(false);
    }
  };

  useEffect(() => {
    setShowAll(false);
    setShowAllNotifications(false);
    // eslint-disable-next-line
  }, [location]);

  const handleViewDetailClick = (item, e) => {
    if (e) {
      e.stopPropagation();
    }
    
    if (item.status === "Document Status Update") {
      navigate(`/projects/overview?projectId=${item.projectId}&tab=Due Diligence`, { state: { fromDocumentStatusUpdate: true } });
    } else if (
      item.status === "Project Created" ||
      (item.status === "Welcome" && item.messages === "You can now start with submitting your first financing request!")
    ) {
      navigate("/projects/getCapital");
    } else if (item.status === "Project Document Request") {
      navigate(`/projects/overview?projectId=${item.actionsNeeded}&tab=Due Diligence`, { state: { fromDocumentStatusUpdate: true } });
    } else if (item.status === "Project Status Update") {
      navigate(`projects/overview?projectId=${item.projectId}`);
    } else if (item.status === "Project Document Request") {
      navigate(`/projects/overview?projectId=${item.projectId}`);
    } else if (item.status === "New Token Purchase Request") {
      navigate(`/projects/overview?projectId=${item.projectId}&tab=Token%20Management&subTab=purchaseRequest`);
    } else if (item.status === "Project Listing" || item.status === "Project Listing Update") {
      navigate(`/projects/overview?projectId=${item.projectId}&tab=Token Management`);
    }
  };

  useEffect(() => {
    if (showAll) {
      setFadeIn(true);
    } else {
      setFadeIn(false);
    }
  }, [showAll]);

  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    function updateSize() {
      if (ref.current?.clientHeight) setHeight(ref.current?.clientHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
    // eslint-disable-next-line
  }, [ref?.current]);

  useEffect(() => {
    const updateCharLimit = () => {
      if (window.innerWidth <= 768) {
        setCharLimit(75);
      } else {
        setCharLimit(130);
      }
    };

    updateCharLimit();
    window.addEventListener("resize", updateCharLimit);
    return () => window.removeEventListener("resize", updateCharLimit);
  }, []);

  const getButtonText = (item) => {
    if (item.status === "Document Status Update") return "View Document";
    if (item.status === "Project Created" ||
        (item.status === "Welcome" && item.messages === "You can now start with submitting your first financing request!")) {
      return "Get Started";
    }
    if (item.status === "Project Document Request") return "Upload";
    if (item.status === "Project Listing" || item.status === "Project Listing Update") return "View Listing";
    return "View Details";
  };

  const shouldShowButton = (item) => {
    const buttonText = getButtonText(item);
    return buttonText !== "View Details";
  };

  return (
    <div className="mb-0 z-0 relative">
      {sortedNotifications.length > 0 && (
        <section className="col-span-2 px-4 flex flex-col gap-4 rounded-lg">
          {deleteLoading && (
            <div className="loader-center">
              <Loader isLoading={true} svgStyle={{ width: "2.5rem", height: "2.5rem" }} />
            </div>
          )}
          {sortedNotifications.length === 0 ? (
            <div className="text-center">No notifications available</div>
          ) : (
            <div className={`flex flex-col gap-4 w-full`}>
              {!showAll && !showAllNotification && (
                <div
                  className={`relative`}
                  style={{
                    height:
                      window.innerWidth < 768
                        ? `${height + 2}px`
                        : `${height + (sortedNotifications.length > 3 ? 36 : sortedNotifications.length > 2 ? 24 : 0)}px`,
                  }}
                >
                  {sortedNotifications.slice(0, window.innerWidth < 768 ? 1 : 3).map((item, index) => {
                    return (
                      <div
                        key={item._id}
                        onClick={() => setShowAll(sortedNotifications?.length > 1 ? true : false)}
                        className={`notification-card flex justify-between items-center p-4 mb-4 rounded-lg shadow-lg absolute left-1/2 -translate-x-1/2 min-h-[92px] 
                        ${index === 0 ? "w-full cursor-pointer" : index === 1 ? "w-[95%]" : "w-[90%]"}`}
                        ref={index === 0 ? ref : null}
                        style={{ zIndex: sortedNotifications.length - index, top: `${index * 16}px` }}
                      >
                        <div className={`w-[85%] flex ${window.innerWidth < 768 ? "items-start" : "items-center"}`}>
                          <IcPercentage className={`w-4 h-4 ${window.innerWidth < 768 ? "mt-2" : ""}`} />
                          <div className="w-full notification-content flex md:items-center flex-grow flex-wrap text-ellipsis ml-3">
                            <p className="flex flex-col md:flex-row md:items-center text-sm">
                              {index === 0 ? (
                                <p className="">{item.messages.length > charLimit ? `${item.messages.substring(0, charLimit) + "..."}` : item.messages}</p>
                              ) : (
                                <p className="text-ellipsis overflow-hidden">
                                  {item.messages.length > charLimit ? `${item.messages.substring(0, charLimit) + "..."}` : item.messages}
                                </p>
                              )}
                              <span className="text-monochrome-20 mt-2 md:mt-0 md:ml-2">{dayjs(item.createdAt).format("DD/MM/YYYY")}</span>
                            </p>
                            {shouldShowButton(item) && (
                              <Button
                                rounded
                                classNames="mt-4 md:mt-0 block md:hidden flex self-start"
                                primary
                                onClick={(e) => handleViewDetailClick(item, e)}
                              >
                                {getButtonText(item)}
                              </Button>
                            )}
                          </div>
                        </div>
                        <div className="ml-4 flex flex-row gap-2 justify-center items-center">
                          {shouldShowButton(item) && (
                            <Button 
                              rounded 
                              classNames="hidden md:block" 
                              primary
                              onClick={(e) => handleViewDetailClick(item, e)}
                            >
                              {getButtonText(item)}
                            </Button>
                          )}
                          <IcCross
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteClick(item._id);
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {(showAllNotification || showAll) && (
                <div
                  className={`flex flex-col rounded-md gap-4 transition-all duration-500 ${
                    showAll || showAllNotification ? "animate-slideDown" : "animate-slideUp"
                  }`}
                >
                  <div className="flex flex-row justify-between">
                    <h3>All Notifications</h3>
                    <button
                      onClick={() => {
                        setShowAll(false);
                        setShowAllNotifications(false);
                      }}
                      className="flex items-center bg-monochrome-5 rounded-lg text-black"
                    >
                      <span className="text-xs p-2">Show Less</span>
                    </button>
                  </div>
                  <div className={`flex flex-col gap-2 md:gap-2 ${showAll || showAllNotification ? "max-h-[60vh] lg:max-h-[70vh] overflow-y-scroll" : ""}`}>
                    {sortedNotifications.slice(0, showAll || showAllNotification ? sortedNotifications.length : 1).map((item) => (
                      <div
                        key={item._id}
                        style={{
                          borderRadius: "0px !important",
                        }}
                        className="notification-card flex justify-between items-center p-4 mb-4 rounded-lg shadow-lg w-full"
                      >
                        <IcPercentage />
                        <div className="notification-content flex-grow flex-wrap text-ellipsis ml-3">
                          <p className="text-sm flex-wrap">
                            {item.messages}
                            <span className="text-monochrome-20 ml-2">{dayjs(item.createdAt).format("DD/MM/YYYY")}</span>
                            {shouldShowButton(item) && (
                              <Button 
                                rounded 
                                classNames="block md:hidden flex self-start text-nowrap" 
                                primary 
                                onClick={(e) => handleViewDetailClick(item, e)}
                              >
                                {getButtonText(item)}
                              </Button>
                            )}
                          </p>
                        </div>
                        <div className="ml-4 flex flex-row gap-2 justify-center items-center">
                          {shouldShowButton(item) && (
                            <Button 
                              rounded 
                              classNames="hidden md:block flex text-nowrap" 
                              primary 
                              onClick={(e) => handleViewDetailClick(item, e)}
                            >
                              {getButtonText(item)}
                            </Button>
                          )}
                          <IcCross
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteClick(item._id);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </section>
      )}
    </div>
  );
};

export default NotificationCard;