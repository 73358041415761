import { IcNextPage } from '@/assets/Icons';
import CapitalNeededStep from '@/components/Projects/GetFinancial/CapitalNeededStep';
import GetCapitalSummery from '@/components/Projects/GetFinancial/GetCapitalSummery';
import RevenueBreakdownStep from '@/components/Projects/GetFinancial/RevenueBreakdownStep';
import StepsBar from '@/components/Projects/GetFinancial/StepsBar';
import GetCapitalSucessModal from '@/components/Projects/GetFinancial/SucessModal';
import SupportingDocuments from '@/components/Projects/GetFinancial/SupportingDocuments';
import { ROUTES } from '@/constants';
import useAuth from '@/hooks/useAuth';
import { createNewAsset, createNewProject } from '@/services/api';
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import countryList from 'react-select-country-list';

const GetCapital = ({ handleLinkClick }) => {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const location = useLocation();
  const queryClient = useQueryClient();
  const { companyId } = useAuth();
  const [currentStep, setCurrentStep] = useState(0);
  const [createdProjectId, setCreatedProjectId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // eslint-disable-next-line
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<string | null>(null);
  const [formDetails, setFormDetails] = useState({
    fundingGoal: "",
    name:"",
    projectDescription:"",
    websiteLink:"",
    assetType: "",
    energyCapacity: "",
    hasRevenueGeneratingAssets: "",
    commercialOperationDate: "",
    typeOfRevenue: [],
    assetLocation: "",
    spvLocation: "",
    dataRoomLink: "",
    files: [],
  });

  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const handleCloseSuccessModal = () => {
    setShowSuccessDialog(false);
    navigate(ROUTES.PROJECTS);
  };

  const handleProjectDetails = () => {
    if (createdProjectId) {
      navigate(`/projects/overview?projectId=${createdProjectId}&tab=Due Diligence`);
    }
  };
  const handleSubmit = () => {
    let { typeOfRevenue, files, assetType, energyCapacity, ...payload }: any = formDetails;
    payload.assetLocation = countryList().getLabel(payload.assetLocation);
    payload.spvLocation = countryList().getLabel(payload.spvLocation);
    payload.revenueStreams = typeOfRevenue.filter((type: any) => type.status).map((type: any) => {
      return {
        type: type.value,
        name: type.name,
        monthlyRevenue: type.yearlyOrMonthly === '0' ? type.projectedRevenue : 0,
        yearlyRevenue: type.yearlyOrMonthly === '1' ? type.projectedRevenue : 0,
      };
    });

    let formData = new FormData();
    formData.append('fundingGoal', payload.fundingGoal);
    formData.append('name', payload.name);
    formData.append('projectDescription', payload.projectDescription);
    formData.append('websiteLink', payload.websiteLink);
    formData.append('hasRevenueGeneratingAssets', payload.hasRevenueGeneratingAssets);
    formData.append('commercialOperationDate', payload.commercialOperationDate);
    formData.append('assetLocation', payload.assetLocation);
    formData.append('spvLocation', payload.spvLocation);
    formData.append('dataRoomLink', payload.dataRoomLink);
    formData.append('revenueStreams', JSON.stringify(payload.revenueStreams));

    files.forEach((file: any) => {
      formData.append('DATA_ROOM', file);
    });
    const assetPayload = {
      assetType: assetType,
      powerCapacity: energyCapacity,
    };
    setIsSubmitting(true);
    createNewAsset(companyId, assetPayload).then((res) => {
      if (res.code === 201 && res.data) {
        formData.append('assetId', JSON.stringify([res.data._id]));
        createProject(formData);
      } else {
        setIsSubmitting(false);
        console.log(res);
      }
    }).catch((err) => {
      setIsSubmitting(false);
      console.log(err);
    });
  };

  const createProject = (formData: FormData) => {
    createNewProject(companyId, formData).then((res) => {
      // console.log("Response After Project Creation: ",res)
      
      if (res.code === 201 && res.data) {
        setCreatedProjectId(res.data._id)
        setShowSuccessDialog(true);
        queryClient.invalidateQueries(["getNotifications"]); 
        setIsSubmitting(false);
      } else {
        setIsSubmitting(false);
      }
    }).catch((err) => {
      console.log(err);
      setIsSubmitting(false);
    });
  };
  // console.log("Project Created: ",createProject)

  // eslint-disable-next-line
  const handleExitConfirm = () => {
    if (pendingNavigation) {
      navigate(pendingNavigation);
    }
    setShowExitConfirmation(false);
  };

  // eslint-disable-next-line
  const handleExitCancel = () => {
    setPendingNavigation(null);
    setShowExitConfirmation(false);
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = ''; // This is required for the confirmation dialog to show
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // useEffect(() => {
  //   const unblock = navigate((prevLocation: any, { location }: any) => {
  //     if (location.pathname !== prevLocation.pathname && currentStep !== 0) {
  //       setPendingNavigation(location.pathname);
  //       setShowExitConfirmation(true);
  //       return false;
  //     }
  //   });

  //   return unblock;
  // }, [currentStep, navigate]);

  return (
    <>
      <div className='flex flex-col mt-4'>
        <div className={"text-monochrome-20 body-regular flex items-center gap-2"}>
          <Link to={'/projects'} onClick={(e) => handleLinkClick(e, ROUTES.PROJECTS)}>Projects</Link>
          <IcNextPage />
          <p className='text-green-100'>Get Capital</p>
        </div>

        <StepsBar currentStep={currentStep} />

        <div className='p-6 bg-white mx-auto w-full lg:w-[80%] mt-4 rounded-lg'>
          {[CapitalNeededStep, RevenueBreakdownStep, SupportingDocuments, GetCapitalSummery].map((Component, index) => (
            currentStep === index && <Component key={index} isSubmitting={isSubmitting} currentStep={currentStep} setCurrentStep={setCurrentStep} setFormDetails={setFormDetails} formDetails={formDetails} handleSubmit={handleSubmit} />
          ))}
        </div>
      </div>
      <GetCapitalSucessModal show={showSuccessDialog} handleClose={handleCloseSuccessModal} handleProjectDetails={handleProjectDetails} />
    </>
  );
};

export default GetCapital;
