import { useNetwork } from "@/provider/NetworkContext";
import { IAuthContext } from "@/types";
import { getTokenExpiration } from "@/utils/tokenUtils";
import { useWeb3Auth } from "@services/Web3AuthService";
import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";

export const AuthContext = createContext<IAuthContext>({
  authToken: null,
  web3AuthToken: null,
  provider: null,
  login: (token: string, web3Token: string | null, web3Provider: any | null) => {},
  logout: () => {},
  switchNetwork: async() => {},
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { selectedNetwork } = useNetwork();
  const { web3auth, initializeWeb3Auth, switchNetwork } = useWeb3Auth();
  const [authToken, setAuthToken] = useState<string | null>(localStorage.getItem("authToken") || null);
  const [web3AuthToken, setWeb3AuthToken] = useState<string | null>(
    localStorage.getItem("web3AuthToken") || null
  );
  const [provider, setProvider] = useState<any | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);
  // eslint-disable-next-line
  const [loginTime, setLoginTime] = useState<number | null>(
    parseInt(localStorage.getItem("loginTime") || "0", 10)
  ); // Initialize with stored login time

  const logout = () => {
    console.log("Session Timed Out");
    localStorage.clear();
    setAuthToken(null);
    setWeb3AuthToken(null);
    setProvider(null);
    setInitialized(false);
    setLoginTime(null); // Clear login time
    window.location.reload();
  };

  // useEffect(() => {
  //   if (authToken && isTokenExpired(authToken, loginTime)) {
  //     logout();
  //   }

  //   const intervalId = setInterval(() => {
  //     const storedAuthToken = localStorage.getItem("authToken");
  //     if (!storedAuthToken || isTokenExpired(storedAuthToken, loginTime)) {
  //       logout();
  //     }
  //   }, 60000); // Check every minute

  //   return () => clearInterval(intervalId);
  // }, [authToken, loginTime]);

  const handleSwitchNetwork = useCallback(async () => {
    try {
      await switchNetwork();
      setProvider(web3auth.provider);
    } catch (error) {
      console.error("Error switching network:", error);
    }
    // eslint-disable-next-line
  }, [switchNetwork]);

  useEffect(() => {
    const initAuth = async () => {
      if (!initialized) {
        await initializeWeb3Auth();
        setProvider(web3auth.provider);
        setInitialized(true);
      } else {
        await handleSwitchNetwork();
      }
    };

    initAuth();
    // eslint-disable-next-line
  }, [initialized, initializeWeb3Auth, handleSwitchNetwork, selectedNetwork]);

  const login = (token: string, web3Token: string | null, web3Provider: any) => {
    localStorage.setItem("authToken", token);
    setAuthToken(token);

    if (web3Token) {
      localStorage.setItem("web3AuthToken", web3Token);
      setWeb3AuthToken(web3Token);
    }

    if (web3Provider) {
      setProvider(web3Provider);
    }

    const currentTime = Date.now();
    localStorage.setItem("loginTime", currentTime.toString());
    setLoginTime(currentTime);

    const expirationTime = getTokenExpiration(token);
    if (expirationTime) {
      const timeoutId = setTimeout(() => {
        logout();
      }, expirationTime - currentTime);

      return () => clearTimeout(timeoutId);
    }
  };

  const contextValue = useMemo(() => ({
    authToken,
    web3AuthToken,
    provider,
    login,
    logout,
    switchNetwork: handleSwitchNetwork,
    // eslint-disable-next-line
  }), [authToken, web3AuthToken, provider, handleSwitchNetwork]);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
