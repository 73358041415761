import IcCross from "@/assets/ic_cross";
import { deleteProject } from "@/services/api";
import { Modal } from "flowbite-react";
import { useState } from "react";
import toast from "react-hot-toast";
import Loader from "./Loader";
import Button from "./UI/Button";

interface DeleteProjectModalProps {
  show: boolean;
  handleClose: () => void;
  projectToBeDeleted: string;
  refetch?: () => void;
}

const DeleteProjectModal = ({ show, handleClose, projectToBeDeleted, refetch }: DeleteProjectModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleDelete = async () => {
    setIsSubmitting(true);
    deleteProject(projectToBeDeleted)
      .then((res: any) => {
        if (res.code === 200) {
          toast.success("Project deleted successfully");

          handleClose();
          refetch && refetch();
        } else {
          toast.error("Failed to delete project. Please try again.");
        }
      })
      .catch((err: any) => {
        toast.error("Failed to delete project. Please try again.");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Modal show={show} onClose={handleClose} position={"center"} size={"2xl"}>
      {/* <Modal.Header className="p-4">Contact Support</Modal.Header> */}
      <Modal.Body className="p-6">
        <div className={"flex justify-between items-center pb-5"}>
          <h3 className="mb-0">Delete Project</h3>
          <Button classNames="w-[32px] bg-monochrome-5 border border-monochrome-10 !px-2 !rounded-xl" rounded onClick={handleClose}>
            <IcCross />
          </Button>
        </div>
        <hr />
        <section className="pt-5">
          <p>Do you really want to delete this project?</p>
          <div className="flex gap-4 justify-end w-full mt-4">
            <Button primary rounded type="button" classNames="h-10" disabled={isSubmitting} onClick={handleClose}>
              Cancel
            </Button>
            <Button type="button" classNames="w-[120px]" secondary rounded disabled={isSubmitting} onClick={handleDelete}>
              {isSubmitting ? <Loader isLoading={true} /> : "Delete"}
            </Button>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteProjectModal;
