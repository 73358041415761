import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import ProjectTabs from "../Tabs/Tabs";
import DetailsCards from "./DetailsCards";

export default function Details({ projectData, refetch }: any) {
  const Asset = useRef(null);
  const General = useRef(null);
  const Valuation = useRef(null);
  const Financing = useRef(null);
  const Documents = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();
  const tabList = ["General", "Asset", "Valuation", "Financing", "Documents"];

  const handleScrollToSection = (currentTab: any) => {
    let ref = { current: null };
    if (currentTab === "General") {
      ref = General;
    } else if (currentTab === "Asset") {
      ref = Asset;
    } else if (currentTab === "Valuation") {
      ref = Valuation;
    } else if (currentTab === "Financing") {
      ref = Financing;
    } else {
      ref = Documents;
    }
    const currentIndex = tabList.indexOf(currentTab);
    setActiveIndex(currentIndex);

    if (ref && ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop - 200,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (location.state && location.state.fromDocumentStatusUpdate) {
      handleScrollToSection("Documents");
    }
    // eslint-disable-next-line
  }, [location.state]);

  useEffect(() => {
    const handleIntersection = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          const currentIndex = tabList.indexOf(entry.target.dataset.section);
          setActiveIndex(currentIndex);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    });

    if (General.current) observer.observe(General.current);
    if (Asset.current) observer.observe(Asset.current);
    if (Valuation.current) observer.observe(Valuation.current);
    if (Financing.current) observer.observe(Financing.current);
    if (Documents.current) observer.observe(Documents.current);

    return () => {
      // eslint-disable-next-line
      if (General.current) observer.unobserve(General.current);
      // eslint-disable-next-line
      if (Asset.current) observer.unobserve(Asset.current);
      // eslint-disable-next-line
      if (Valuation.current) observer.unobserve(Valuation.current);
      // eslint-disable-next-line
      if (Financing.current) observer.unobserve(Financing.current);
      // eslint-disable-next-line
      if (Documents.current) observer.unobserve(Documents.current);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <ProjectTabs
        activeIndex={activeIndex}
        onSelectTab={handleScrollToSection}
        tabList={tabList}
        uniqueId="detail-tab-component"
        classNames="sticky top-[60px] bg-[#f7f5f2] z-[9]"
      />
      <DetailsCards refs={{ Documents, Financing, Valuation, General, Asset }} data={projectData} refetch={refetch} />
    </div>
  );
}
