import IcDropdownChecked from "@/assets/Icons/ic_dropdown_checked";
import React from "react";
import Select, { components } from "react-select";
import { DropdownProps, Option } from "./DropdownProps";
const { Control } = components;

const Dropdown: React.FC<DropdownProps> = ({
  options,
  onChange,
  placeholder,
  icon,
  value,
  disabled,
  className,
  height,
  isMulti=false,
  menuBoxMarginTop = 25,
  isSearchable = false,
}) => {
  const handleChange = (selectedOption: Option | null) => {
    onChange(selectedOption);
  };

  return (
    <Select
      options={options}
      className={className}
      isMulti={isMulti}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          border: "none",
          boxShadow: "none",
          borderColor: "transparent",
          height: height,
          color: "white",
          fontSize: "12px",
          background: 'white',
          borderRadius: "6px",
        }),
        menu: (provided) => ({
          ...provided,
          marginTop: menuBoxMarginTop + "px",
          backgroundColor: "white",
          borderRadius: "10px",
          borderColor: "transparent",
          color: "black",
          padding: "0",
        }),
      }}
      value={options?.find((option) => option.value === value)}
      components={{
        IndicatorSeparator: () => null,
        Control: ({children, ...props}: any)=>{
          const selected = props.getValue()[0];
            return (<Control className="flex items-center gap-2 h-full w-full  body-small-regular !h-[33px] !min-h-[33px] select-dropdown" {...props}>
              {
                selected && selected?.icon ? <img className="w-4 aspect-square" alt="" src={selected.icon}></img> : icon
              }
              {children}
            </Control>)
        },
        Option: ({innerProps, label, data, isSelected}: any) =>{
          return (<div className={`flex items-center justify-between gap-2 h-10 px-2 cursor-pointer first:border-none border-t border-[#F6F6F6] ${
            isSelected
                ? 'bg-[#DFDFDF]'
                : 'hover:bg-[#F6F6F6]'
        }`} {...innerProps}>
           <div className="flex items-center gap-2">
             {
              data.icon && <img className="w-4 aspect-square" alt="" src={data.icon}></img>
            }
            <span className={`body-small-regular`}>{label}</span>
           </div>
            {isSelected && <IcDropdownChecked/>}
          </div>)
        }
      }}
      isSearchable={isSearchable}
      onChange={(val) => {
        handleChange(val);
      }}
      isDisabled={disabled}
    />
  );
};

export default Dropdown;