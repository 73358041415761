const removeCommas = (value: string) => {
    if (value) {
      return value.replace(/,/g, ""); // Remove commas
    }
  };

  const formatNumberWithCommas = (value: any) => {
    if (value === "" || value === undefined) {
      return;
    }
    // const num = value.replace(/\D/g, ""); // Remove non-digit characters
    // return num.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas

      // Split the value into integer and decimal parts
    const [integerPart, decimalPart] = value.split('.');

    // Remove non-digit characters from the integer part
    const formattedIntegerPart = integerPart.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Preserve the decimal part if it exists
    return decimalPart
      ? `${formattedIntegerPart}.${decimalPart.replace(/\D/g, "")}` 
      : formattedIntegerPart;
  };

  export {
    removeCommas,
    formatNumberWithCommas,
  }