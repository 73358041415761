import { IcDropdownChecked } from "@/assets/menu";
import Button from "@/components/UI/Button";
import { Modal } from "flowbite-react";

interface ContactSupportModalProps {
    show: boolean;
    handleClose: () => void;
}
const ContactSupportSucessModal = ({ show, handleClose }: ContactSupportModalProps) => {
    return (
        <Modal show={show} onClose={handleClose} position={'center'} size={'2xl'}>
            <Modal.Body className="p-4">
                {/* <div className={"flex justify-between items-center pb-5"}>
                    <h3 className="mb-0">
                        Contact Support
                    </h3>
                    <Button classNames="w-[32px] bg-monochrome-5 border border-monochrome-10 !px-2 !rounded-xl" rounded
                        onClick={handleClose}>
                        <IcCross />
                    </Button>
                </div>
                <hr /> */}
                <section className="p-4 flex flex-col gap-7 items-center">
                    <IcDropdownChecked className={'h-10 w-10 bg-green-200 flex items-center justify-center rounded-full !z-50'}></IcDropdownChecked>
                    <div className="flex flex-col gap-2 items-center">
                        <h3>Submission Successful</h3>
                        <p className="body-small-regular w-[76%] text-center">Thank you for reaching out! We will review your inquiry and respond as soon as possible.</p>
                    </div>
                    <Button primary rounded onClick={handleClose} classNames="w-full">Close</Button>
                </section>
            </Modal.Body>
        </Modal>
    )
}

export default ContactSupportSucessModal