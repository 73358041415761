// // import React, { useContext, useEffect, useState } from "react";
// // import Web3 from "web3";
// // import { AuthContext } from "@/provider/AuthContext";
// // import Loader from "./Loader";
// // import Button from "./UI/Button";
// // import { IcTopUp, IcWithdrow } from "@/assets";
// // import TopUpWallet from "./TopUpWallet";
// // import WithdrawWallet from "./WithdrawWallet";

// // // USDC contract address on Polygon
// // const USDC_CONTRACT_ADDRESS = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359";

// // // ERC-20 token ABI (simplified to include balanceOf function)
// // const ERC20_ABI = [
// //   {
// //     constant: true,
// //     inputs: [{ name: "_owner", type: "address" }],
// //     name: "balanceOf",
// //     outputs: [{ name: "balance", type: "uint256" }],
// //     type: "function",
// //   },
// // ];

// // const BalanceCard = () => {
// //   const { provider } = useContext(AuthContext);
// //   const [usdcBalance, setUsdcBalance] = useState<string>("0.00");
// //   const [polBalance, setPolBalance] = useState<string>("0.00");
// //   const [isLoading, setIsLoading] = useState<boolean>(true);
// //   const [showTopUp, setShowTopUp] = useState<boolean>(false);
// //   const [showWithdraw, setShowWithdraw] = useState<boolean>(false);
// //   const [symbol, setSymbol] = useState("USDC");

// //   useEffect(() => {
// //     if (!provider) {
// //       console.error("Web3Auth provider is not available");
// //       setIsLoading(false);
// //       return;
// //     }

// //     const web3 = new Web3(provider);

// //     const fetchBalances = async () => {
// //       try {
// //         const accounts = await web3.eth.getAccounts();
// //         if (!accounts || accounts.length === 0) {
// //           console.error("No account found.");
// //           setIsLoading(false);
// //           return;
// //         }

// //         const walletAddress = accounts[0];
// //         console.log("Wallet Address: ", walletAddress);

// //         // Fetch USDC balance
// //         const usdcContract = new web3.eth.Contract(ERC20_ABI, USDC_CONTRACT_ADDRESS);
// //         const balanceInSmallestUnit = await usdcContract.methods.balanceOf(walletAddress).call();
// //         const balanceInUSDC = web3.utils.fromWei(balanceInSmallestUnit, "mwei"); // USDC has 6 decimals
// //         setUsdcBalance(balanceInUSDC);

// //         // Fetch POL (MATIC) balance
// //         const balanceInWei = await web3.eth.getBalance(walletAddress);
// //         const balanceInPOL = web3.utils.fromWei(balanceInWei, "ether"); // POL has 18 decimals
// //         setPolBalance(balanceInPOL);

// //         setIsLoading(false);
// //       } catch (error) {
// //         console.error("Error fetching balances: ", error);
// //         setIsLoading(false);
// //       }
// //     };

// //     fetchBalances();
// //   }, [provider]);

// //   const handleTopUpClick = () => {
// //     setShowTopUp(true);
// //   };

// //   const handleWithdrawClick = (symbol: string) => {
// //     setShowWithdraw(true);
// //     setSymbol(symbol);
// //   };

// //   return (
// //     <div className="bg-monochrome-100 p-4 flex flex-column rounded-lg">
// //       <div className="flex flex-row gap-4 items-start">
// //         <img src="./assets/ic_wallet.svg" alt="Wallet Icon" />
// //         <div className="flex flex-column gap-2">
// //           <p className="text-[#9E9E9E] text-[0.688rem]">Wallet Balance</p>
// //           {isLoading ? (
// //             <Loader isLoading={isLoading} />
// //           ) : (
// //             <>
// //             <div className="flex items-center gap-x-10">
// //             <span className="font-medium text-[2rem]">
// //                 {usdcBalance} <sub className="text-monochrome-20 text-base relative bottom-0">USDC</sub>
// //               </span>
// //               <span className="font-medium text-[2rem]">
// //                 {polBalance} <sub className="text-monochrome-20 text-base relative bottom-0">POL</sub>
// //               </span>

// //             </div>
// //             </>
// //           )}
// //         </div>
// //       </div>
// //       <div className="flex flex-row mt-4 gap-4">
// //         <Button primary rounded classNames="w-full sm:w-6/12 !text-[0.75rem] font-normal" onClick={handleTopUpClick}>
// //           <IcTopUp />
// //           Top Up
// //         </Button>
// //         <Button primary rounded classNames="w-full sm:w-6/12 !text-[0.75rem] font-normal" onClick={() => handleWithdrawClick("USDC")}>
// //           <IcWithdrow />
// //           Withdraw
// //         </Button>
// //       </div>

// //       {showTopUp && <TopUpWallet closeModal={() => setShowTopUp(false)} />}
// //       {showWithdraw && <WithdrawWallet symbol={symbol} closeModal={() => setShowWithdraw(false)} />}
// //     </div>
// //   );
// // };

// // export default BalanceCard;




// import React, { useContext, useEffect, useState } from "react";
// import Web3 from "web3";
// import { AuthContext } from "@/provider/AuthContext";
// import Loader from "./Loader";
// import Button from "./UI/Button";
// import { IcTopUp, IcWithdrow } from "@/assets";
// import TopUpWallet from "./TopUpWallet";
// import WithdrawWallet from "./WithdrawWallet";

// const BalanceCard = () => {
//   const { provider } = useContext(AuthContext);
//   const [peaqBalance, setPeaqBalance] = useState<string>("0.00");
//   const [isLoading, setIsLoading] = useState<boolean>(true);
//   const [showTopUp, setShowTopUp] = useState<boolean>(false);
//   const [showWithdraw, setShowWithdraw] = useState<boolean>(false);
//   const [symbol, setSymbol] = useState("PEAQ");

//   useEffect(() => {
//     if (!provider) {
//       console.error("Web3Auth provider is not available");
//       setIsLoading(false);
//       return;
//     }

//     const web3 = new Web3(provider);

//     const fetchBalance = async () => {
//       try {
//         const accounts = await web3.eth.getAccounts();
//         if (!accounts || accounts.length === 0) {
//           console.error("No account found.");
//           setIsLoading(false);
//           return;
//         }

//         const walletAddress = accounts[0];
//         console.log("Wallet Address: ", walletAddress);

//         // Fetch PEAQ balance (native token of PEAQ chain)
//         const balanceInWei = await web3.eth.getBalance(walletAddress);
//         const balanceInPEAQ = web3.utils.fromWei(balanceInWei, "ether"); // PEAQ has 18 decimals
//         setPeaqBalance(balanceInPEAQ);

//         setIsLoading(false);
//       } catch (error) {
//         console.error("Error fetching balance: ", error);
//         setIsLoading(false);
//       }
//     };

//     fetchBalance();
//   }, [provider]);

//   const handleTopUpClick = () => {
//     setShowTopUp(true);
//   };

//   const handleWithdrawClick = (symbol: string) => {
//     setShowWithdraw(true);
//     setSymbol(symbol);
//   };

//   return (
//     <div className="bg-monochrome-100 p-4 flex flex-column rounded-lg">
//       <div className="flex flex-row gap-4 items-start">
//         <img src="./assets/ic_wallet.svg" alt="Wallet Icon" />
//         <div className="flex flex-column gap-2">
//           <p className="text-[#9E9E9E] text-[0.688rem]">Wallet Balance</p>
//           {isLoading ? (
//             <Loader isLoading={isLoading} />
//           ) : (
//             <div className="flex items-center gap-x-10">
//               <span className="font-medium text-[2rem]">
//                 {peaqBalance} <sub className="text-monochrome-20 text-base relative bottom-0">PEAQ</sub>
//               </span>
//             </div>
//           )}
//         </div>
//       </div>
//       <div className="flex flex-row mt-4 gap-4">
//         <Button primary rounded classNames="w-full sm:w-6/12 !text-[0.75rem] font-normal" onClick={handleTopUpClick}>
//           <IcTopUp />
//           Top Up
//         </Button>
//         <Button primary rounded classNames="w-full sm:w-6/12 !text-[0.75rem] font-normal" onClick={() => handleWithdrawClick("PEAQ")}>
//           <IcWithdrow />
//           Withdraw
//         </Button>
//       </div>

//       {showTopUp && <TopUpWallet closeModal={() => setShowTopUp(false)} />}
//       {showWithdraw && <WithdrawWallet symbol={symbol} closeModal={() => setShowWithdraw(false)} />}
//     </div>
//   );
// };

// export default BalanceCard;


import { IcTopUp, IcWithdrow } from "@/assets";
import { IcTotalFinancingReceived } from "@/assets/Icons";
import { numberWithCommas } from "@/constants/extensions";
import { useNetwork } from "@/provider/NetworkContext";
import { AuthContext } from "@/services/AuthContext";
import { useContext, useEffect, useState } from "react";
import Web3 from "web3";
import Loader from "./Loader";
import TopUpWallet from "./TopUpWallet";
import Button from "./UI/Button";
import WithdrawWallet from "./WithdrawWallet";

// USDC contract address on Polygon
const USDC_CONTRACT_ADDRESS = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359";

// ERC-20 token ABI (simplified to include balanceOf function)
const ERC20_ABI = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
];

const WithdrawBalanceCard = () => {
  const { provider } = useContext(AuthContext);
  const { selectedNetwork } = useNetwork(); 
  const [usdcBalance, setUsdcBalance] = useState<string>("0.00");
  // eslint-disable-next-line
  const [polBalance, setPolBalance] = useState<string>("0.00");
  const [peaqBalance, setPeaqBalance] = useState<string>("0.00");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showTopUp, setShowTopUp] = useState<boolean>(false);
  const [showWithdraw, setShowWithdraw] = useState<boolean>(false);
  // eslint-disable-next-line
  const [symbol, setSymbol] = useState<string>("");
  

  useEffect(() => {
    if (!provider) {
      console.error("Web3Auth provider is not available");
      setIsLoading(false);
      return;
    }

    const web3 = new Web3(provider);

    const fetchBalances = async () => {
      try {
        const accounts = await web3.eth.getAccounts();
        if (!accounts || accounts.length === 0) {
          console.error("No account found.");
          setIsLoading(false);
          return;
        }

        const walletAddress = accounts[0];
        console.log("Wallet Address: ", walletAddress);

        if (selectedNetwork === "polygon") {
          // Fetch USDC balance
          const usdcContract = new web3.eth.Contract(ERC20_ABI, USDC_CONTRACT_ADDRESS);
          const balanceInSmallestUnit = await usdcContract.methods.balanceOf(walletAddress).call();
          const balanceInUSDC = web3.utils.fromWei(balanceInSmallestUnit, "mwei"); // USDC has 6 decimals
          setUsdcBalance(balanceInUSDC);

          // Fetch POL (MATIC) balance
          const balanceInWei = await web3.eth.getBalance(walletAddress);
          const balanceInPOL = web3.utils.fromWei(balanceInWei, "ether"); // POL (MATIC) has 18 decimals
          setPolBalance(balanceInPOL);
        }

        if (selectedNetwork === "peaq") {
          // Fetch PEAQ balance (native token of PEAQ chain)
          const balanceInWei = await web3.eth.getBalance(walletAddress);
          const balanceInPEAQ = web3.utils.fromWei(balanceInWei, "ether"); // PEAQ has 18 decimals
          setPeaqBalance(balanceInPEAQ);
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching balances: ", error);
        setIsLoading(false);
      }
    };

    fetchBalances();
  }, [provider, selectedNetwork]);

  const handleTopUpClick = () => {
    setShowTopUp(true);
  };

  const handleWithdrawClick = (symbol: string) => {
    setShowWithdraw(true);
    setSymbol(symbol);
  };

  return (
    <div className="bg-white p-4 flex gap-4 flex-column justify-between rounded-lg">
      <div className="flex flex-row gap-4 items-start">
        {/* <img src="./assets/ic_wallet.svg" alt="Wallet Icon" /> */}
        <IcTotalFinancingReceived />
        <div className="flex flex-column gap-2">
          <p className="text-[#9E9E9E] text-[0.688rem]">Wallet Balance</p>
          {isLoading ? (
            <Loader isLoading={isLoading} />
          ) : (
            <>
              {selectedNetwork === "polygon" && (
                <div className="flex flex-col gap-4 items-start">
                
                  {/* <span className="font-medium text-[2rem]">
                    {parseFloat(polBalance).toFixed(2)} <sub className="text-monochrome-20 text-base relative bottom-0">USDC</sub>
                  </span> */}
                  <span className="flex flex-row gap-2 items-center font-medium text-[2rem]">
                   <img src="https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=035" alt="" className="flex w-5 h-5" />
                     {/* <sub className="text-monochrome-20 text-sm relative bottom-0">{usdcBalance} USDC</sub> */}
                     <h1>{numberWithCommas(Number(usdcBalance))} <span className="text-monochrome-30 leading-6 text-base">USDC</span></h1>
                  </span>
                </div>
              )}
              {selectedNetwork === "peaq" && (
                <div className="flex items-center  gap-x-10">
                  {/* <span className="font-medium text-[2rem]">
                  <img src="https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=035" alt="USDC Image" className="flex w-5 h-5" />
                    {parseFloat(peaqBalance).toFixed(2)} <sub className="text-monochrome-20 text-base relative bottom-0"> {peaqBalance} USDC</sub>
                  </span> */}

                    {/* TODO: Still shows Peaq balance , NEEd to get USDC Contract address on PEAQ and Display it  */}
                  {/* <span className="flex flex-row gap-2 font-medium text-[2rem]">
                   <img src="https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=035" alt="USDC Image" className="flex w-5 h-5" />
                     <sub className="text-monochrome-20 text-sm relative bottom-0">{peaqBalance} USDC</sub>

                  </span> */}
                  <span className="flex flex-row gap-2 items-center font-medium text-[2rem]">
                   <img src="https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=035" alt="" className="flex w-5 h-5" />
                     {/* <sub className="text-monochrome-20 text-sm relative bottom-0">{usdcBalance} USDC</sub> */}
                     <h1>{numberWithCommas(Number(peaqBalance))} <span className="text-monochrome-30 leading-6 text-base">USDC</span></h1>
                  </span>
                </div>
              )}
              {selectedNetwork === "ethereum" && (
                <div className="flex items-center  mt-2 gap-x-10">
                  <span className="font-medium text-[2rem]">
                    {parseFloat(peaqBalance).toFixed(2)} <sub className="text-monochrome-20 text-base relative bottom-0">USDC</sub>
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="flex flex-row  gap-4">
        <Button primary rounded classNames="w-full sm:w-6/12 !text-[0.75rem] font-normal xl:px-2 " onClick={handleTopUpClick}>
          <IcTopUp />
          Top Up
        </Button>
        <Button primary rounded classNames="w-full sm:w-6/12 !text-[0.75rem] font-normal" onClick={() => handleWithdrawClick(selectedNetwork === "polygon" ? "USDC" : "USDC")}>
          <IcWithdrow />
          Withdraw
        </Button>
      </div>

      {showTopUp && <TopUpWallet closeModal={() => setShowTopUp(false)} />}
      {showWithdraw && <WithdrawWallet networkType={selectedNetwork} closeModal={() => setShowWithdraw(false)} />}
    </div>
  );
};

export default WithdrawBalanceCard;
