import { ROUTES } from "@/constants";
import { AuthContext } from "@/services/AuthContext";
import { useWeb3Auth, web3authInstance } from "@/services/Web3AuthService";
import useStore from "@/store/useStore";
import { useContext } from "react";
import { IoLogOutOutline } from "react-icons/io5";
import { NavLink, useNavigate } from "react-router-dom";

const Logout = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const { reset } = useStore();
  // eslint-disable-next-line
  const { web3auth, initializeWeb3Auth } = useWeb3Auth();

  const handleLogout = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();

    try {
      if (web3authInstance.connected) {
        // Check if the user is connected before logging out
        await web3authInstance.logout();
      }
    } catch (error) {
      console.error("Web3Auth logout error:", error);
    }

    logout(); // Log out from your application
    localStorage.removeItem("authToken");
    localStorage.removeItem("web3AuthToken");
    reset(); // Reset store data
    navigate(ROUTES.APP_ROOT); // Redirect to the home page
    window.location.reload(); // Ensure a fresh state
  };

  return (
    <NavLink onClick={handleLogout} className="text-[#FF1E1E] flex items-center gap-4">
      <IoLogOutOutline className="nav-icon" /> <span>Logout</span>
    </NavLink>
  );
};

export default Logout;
