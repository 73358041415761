import { IcClose } from '@/assets/menu';
import Loader from '@/components/Loader';
import Button from '@/components/UI/Button';
import { fetchAllNotifications, readAllNotifications, readNotification } from '@/services/api';
import useStore from '@/store/useStore';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Notifications.css';

type Notification = {
    _id: number;
    icon: string;
    color: string;
    status?: string;
    createdAt: string;
    updatedAt?: string;
    messages: string;
    linkText?: string;
};

type NotificationsType = {
    [key: string]: Notification[];
};

const Notifications: React.FC = () => {
    const { userData } = useStore();
    const navigate = useNavigate();
    const id = userData?._id || userData?.investorId;

    const [deleteLoading, setDeleteLoading] = useState(false);

    const { data: notification = [], isLoading: isLoadingUser, refetch } = useQuery({
        queryKey: ["getNotifications", id],
        queryFn: () => fetchAllNotifications(id),
        enabled: !!id,
        select: (data: any) => data.data
    });

    const sortedNotifications = notification.sort((a: any, b: any) => {
        return new Date(b.updatedAt || b.createdAt).getTime() - new Date(a.updatedAt || a.createdAt).getTime();
    });

    const allNotifications = sortedNotifications.map((item: any) => ({
        ...item,
        icon: "bi bi-coin",
        color: "#1DD05D"
    }));

    const mostRecent = [...allNotifications].slice(0, 4);
    const earlier = allNotifications.filter((item: any) => !mostRecent.some((rect: any) => rect._id === item._id));

    const notifications: NotificationsType = {
        "All Notifications": allNotifications,
        "Most Recent": mostRecent,
        "Earlier": earlier
    };

    const handleDeleteNotification = async (_id: string) => {
        setDeleteLoading(true);
        await readNotification(_id.toString());
        await refetch();
        setDeleteLoading(false);
    };

    const handleNagivation = (path: string) => {
        navigate(path);
    };

    const handleClearAll = async () => {
        setDeleteLoading(true);
        await readAllNotifications(id);
        await refetch();
        setDeleteLoading(false);
    };

    return (
        <>
            {(isLoadingUser || deleteLoading) && <div className="min-h-[40px] loader-center"><Loader isLoading={true} svgStyle={{ width: "2.5rem", height: "2.5rem" }} /></div>}
            {Object.keys(notifications).map((item: string, index: number) => {
                return (
                    <div className="bg-white p-5 rounded-lg mb-7 relative" key={index}>
                        <div className='flex justify-between items-center'>
                            <h2 className='text-base font-medium'>{item}</h2>
                            {item === "All Notifications" && allNotifications.length > 0 && (
                                <Button primary rounded classNames='text-sm' onClick={handleClearAll}>
                                    Clear All
                                </Button>
                            )}
                        </div>
                        <div className='divide-y divide-monochrome-10'>
                            {notifications[item].map(notification => (
                                <div key={notification._id} className="flex items-center flex-nowrap py-6 px-4 gap-4 relative">
                                    <div className="p-0 text-xl w-fit" style={{ color: notification.color }}>
                                        <i className={notification.icon}></i>
                                    </div>
                                    <div className="flex justify-between w-full">
                                        <div>
                                            <p className='text-monochrome-100 text-sm'>
                                                {notification.messages}
                                                {notification?.linkText && <button className='underline'>{notification?.linkText}</button>}
                                            </p>
                                            <p className="text-monochrome-30 text-sm mt-1">{dayjs(notification.updatedAt || notification.createdAt).format("DD/MM/YYYY")}</p>
                                        </div>
                                        <div className={`flex items-center w-[300px] ${notification.status === "Complete KYC" || notification.status === "Complete KYB" ? "justify-between" : "justify-end"}`}>
                                            {(notification.status === "Complete KYC" || notification.status === "Complete KYB") && (
                                                <Button primary rounded classNames='' onClick={() => {
                                                    handleNagivation('/settings');
                                                }}>{notification.status}</Button>
                                            )}
                                        </div>
                                    </div>
                                    <div className="absolute top-0 right-0 mt-3 mr-3 cursor-pointer" onClick={() => handleDeleteNotification(notification._id)}><IcClose /></div>
                                </div>
                            ))}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default Notifications;
