
const StepsBar = ({ currentStep }:any) => {
    return (
        <div className='mt-4 w-full lg:w-[80%] body-small-regular p-4 mx-auto flex'>
            <div className='w-1/4 text-center'>
                <div className='flex items-center gap-2 lg:gap-4 mb-4'>
                    <div className='bg-none w-6 sm:w-10 md:w-16 lg:w-1/3 h-0.5'></div>
                    <div className={`mx-auto rounded-full w-8 h-8 flex items-center justify-center ${currentStep === 0 ? "border-2 border-green-100 bg-white drop-shadow-[0_0_6px_#00C798]" : currentStep > 0 ? "bg-green-500" : "border-2 border-monochrome-10"}`}>
                        <div className={`w-2 h-2 ${currentStep === 0 ? "bg-green-100" : currentStep > 0 ? "bg-white" : "bg-monochrome-10"} rounded-full`}></div>
                    </div>
                    <div className='bg-gray-300 w-6 sm:w-10 md:w-16 lg:w-1/3 h-0.5'></div>
                </div>
                <p className={`${currentStep >= 0 ? "text-black" : "text-monochrome-30"}`}>Capital Needed</p>
            </div>
            <div className='w-1/4 text-center'>
                <div className='flex items-center gap-2 lg:gap-4 mb-4'>
                    <div className='bg-gray-300 w-6 sm:w-10 md:w-16 lg:w-1/3 h-0.5'></div>
                    <div className={`mx-auto rounded-full w-8 h-8 flex items-center justify-center ${currentStep === 1 ? "border-2 border-green-100 bg-white drop-shadow-[0_0_6px_#00C798]" : currentStep > 1 ? "bg-green-500" : "border-2 border-monochrome-10"}`}>
                        <div className={`w-2 h-2 ${currentStep === 1 ? "bg-green-100" : currentStep > 1 ? "bg-white" : "bg-monochrome-10"} rounded-full`}></div>
                    </div>
                    <div className='bg-gray-300 w-6 sm:w-10 md:w-16 lg:w-1/3 h-0.5'></div>
                </div>
                <p className={`${currentStep >= 1 ? "text-black" : "text-monochrome-30"}`}>Revenue Breakdown</p>
            </div>
            <div className='w-1/4 text-center'>
                <div className='flex items-center gap-2 lg:gap-4 mb-4'>
                    <div className='bg-gray-300 w-6 sm:w-10 md:w-16 lg:w-1/3 h-0.5'></div>
                    <div className={`mx-auto rounded-full w-8 h-8 flex items-center justify-center ${currentStep === 2 ? "border-2 border-green-100 bg-white drop-shadow-[0_0_6px_#00C798]" : currentStep > 2 ? "bg-green-500" : "border-2 border-monochrome-10"}`}>
                        <div className={`w-2 h-2 ${currentStep === 2 ? "bg-green-100" : currentStep > 2 ? "bg-white" : "bg-monochrome-10"} rounded-full`}></div>
                    </div>
                    <div className='bg-gray-300 w-6 sm:w-10 md:w-16 lg:w-1/3 h-0.5'></div>
                </div>
                <p className={`${currentStep >= 2 ? "text-black" : "text-monochrome-30"}`}>Supporting Documents</p>
            </div>
            <div className='w-1/4 text-center'>
                <div className='flex items-center gap-2 lg:gap-4 mb-4'>
                    <div className='bg-gray-300 w-6 sm:w-10 md:w-16 lg:w-1/3 h-0.5'></div>
                    <div className={`mx-auto rounded-full w-8 h-8 flex items-center justify-center ${currentStep === 3 ? "border-2 border-green-100 bg-white drop-shadow-[0_0_6px_#00C798]" : currentStep > 3 ? "bg-green-500" : "border-2 border-monochrome-10"}`}>
                        <div className={`w-2 h-2 ${currentStep === 3 ? "bg-green-100" : currentStep > 3 ? "bg-white" : "bg-monochrome-10"} rounded-full`}></div>
                    </div>
                    <div className=' bg-none w-6 sm:w-10 md:w-16 lg:w-1/3 h-0.5'></div>
                </div>
                <p className={`${currentStep >= 3 ? "text-black" : "text-monochrome-30"}`}>Submit</p>
            </div>
        </div>
    )
}

export default StepsBar