import { IcCompanySettings, IcUser } from "@/assets/Icons";
import BankDetails from "@/components/Settings/BankDetails";
import BasicCompanyDetails from "@/components/Settings/BasicCompanyDetails";
import UserBasicDetails from "@/components/Settings/UserBasicDetails";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const SettingsPage = () => {
  const [userFormIncomplete, setUserFormIncomplete] = useState(true);
  const [bankFormIncomplete, setBankFormIncomplete] = useState(true);
  const [companyFormIncomplete, setCompanyFormIncomplete] = useState(true);
  const location = useLocation();
  useEffect(() => {
    if (location.state?.activateBankDetails) {
      setSelectedComponent({
        link: 'bankDetails',
        components: settingComponents.companySetting.list.bankDetails.component,
      });
    }
    // eslint-disable-next-line
  }, [location.state]);
  
  const [settingComponents, setSettingComponents] = useState({
    userSetting: {
      title: "User Settings",
      icon: <IcUser className="text-green-500" />,
      list: {
        account: {
          title: "User Basic Details",
          actionNeed: userFormIncomplete,
          component: <UserBasicDetails updateUserFormStatus={setUserFormIncomplete} />,
        },
      },
    },
    companySetting: {
      title: "Company Settings",
      icon: <IcCompanySettings className="text-green-500" />,
      list: {
        companyDetails: {
          title: "Basic Company Details",
          actionNeed: companyFormIncomplete,
          component: <BasicCompanyDetails updateCompanyFormStatus={setCompanyFormIncomplete} />,
        },
        bankDetails: {
          title: "Bank Details",
          actionNeed: bankFormIncomplete,
          component: <BankDetails updateBankFormStatus={setBankFormIncomplete} />,
        },
      },
    },
  });

  const [selectedComponent, setSelectedComponent] = useState({
    link: "account",
    components: settingComponents.userSetting.list.account.component,
  });

  const checkFormCompletionStatus = () => {
    const isUserFormComplete = true;
    const isCompanyFormComplete = true;
    const isBankFormComplete = true;

    setUserFormIncomplete(!isUserFormComplete);
    setCompanyFormIncomplete(!isCompanyFormComplete);
    setBankFormIncomplete(!isBankFormComplete);
  };

  useEffect(() => {
    checkFormCompletionStatus();
  }, []);

  // Update actionNeed status for User Basic Details
  useEffect(() => {
    setSettingComponents((prevComponents) => ({
      ...prevComponents,
      userSetting: {
        ...prevComponents.userSetting,
        list: {
          ...prevComponents.userSetting.list,
          account: {
            ...prevComponents.userSetting.list.account,
            actionNeed: userFormIncomplete,
          },
        },
      },
    }));
  }, [userFormIncomplete]);

  // Update actionNeed status for Basic Company Details
  useEffect(() => {
    setSettingComponents((prevComponents) => ({
      ...prevComponents,
      companySetting: {
        ...prevComponents.companySetting,
        list: {
          ...prevComponents.companySetting.list,
          companyDetails: {
            ...prevComponents.companySetting.list.companyDetails,
            actionNeed: companyFormIncomplete,
          },
        },
      },
    }));
  }, [companyFormIncomplete]);

  // Update actionNeed status for Bank Details
  useEffect(() => {
    setSettingComponents((prevComponents) => ({
      ...prevComponents,
      companySetting: {
        ...prevComponents.companySetting,
        list: {
          ...prevComponents.companySetting.list,
          bankDetails: {
            ...prevComponents.companySetting.list.bankDetails,
            actionNeed: bankFormIncomplete,
          },
        },
      },
    }));
  }, [bankFormIncomplete]);

  return (
    <div className="flex flex-col xl:flex-row divide-y xl:divide-y-0 xl:divide-x divide-monochrome-0 h-full">
      <div className="flex gap-8 flex-col md:flex-row xl:flex-col xl:w-[300px] pr-4 !pb-4 xl:pb-0">
        {Object.keys(settingComponents).map((key, index) => (
          <div className="flex flex-col gap-4" key={'main-' + index}>
            <div className="flex gap-4 text-monochrome-30 body-small-regular">
              {settingComponents[key].icon}
              <span>{settingComponents[key].title}</span>
            </div>
            <div className="flex xl:flex-col gap-4">
              {Object.keys(settingComponents[key].list).map((innerKey, innerIndex) => (
                <div
                  key={'inner-' + innerIndex}
                  onClick={() => {
                    setSelectedComponent({
                      link: innerKey,
                      components: settingComponents[key].list[innerKey].component,
                    });
                  }}
                  className={`flex justify-between items-center gap-4 py-3 px-4 body-regular border border-monochrome-10 rounded-lg cursor-pointer ${innerKey === selectedComponent.link ? 'bg-white border-white' : ''}`}
                >
                  <span>{settingComponents[key].list[innerKey].title}</span>
                  {settingComponents[key].list[innerKey].actionNeed && <p className="rounded-full w-2 h-2 bg-danger-100"></p>}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="pt-3 !xl:pt-0 xl:p-4 w-full">
        {selectedComponent.components}
      </div>
    </div>
  );
};

export default SettingsPage;
