import { ROUTES } from "@/constants";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Get the current year dynamically
  const { pathname } = useLocation();

  return (
    <footer
      className={`z-50 w-full rounded-[8px] flex flex-row  items-center justify-center py-4 bg-white ${
        pathname === ROUTES.APP_ROOT || pathname === ROUTES.PROFILE_SETUP ? "" : "w-full lg:w-[calc(100%-25px)] xl:w-[calc(100%-30px)] mx-auto"
      } text-center text-sm py-5 `}
    >

      <div>
        <p className="body-regular">© {currentYear} penomo Foundation Ltd.</p>
      </div>
    </footer>
  );
};

export default Footer;
