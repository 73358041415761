import { IcNextPage, IcPreviousPage, IcSearch } from "@/assets";
import { useNetwork } from "@/provider/NetworkContext";
import { getAllTokenHolders } from "@/services/api";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

const perPageItems = [10, 25, 50, 100];

export default function HoldersList({ projectData }: any) {
  const tokenId = projectData?.tokenIds[0]?._id;
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(perPageItems[0]);
  const [filteredData, setFilteredData] = useState([]);
  // eslint-disable-next-line
  const { selectedNetwork } = useNetwork();

  const {
    data: holdersData,
    isLoading,
    // eslint-disable-next-line
    refetch,
  } = useQuery({
    queryKey: ["getAllTokenHolders", tokenId],
    queryFn: () => getAllTokenHolders(tokenId),
    enabled: !!tokenId,
    select: (res) => res.data,
  });

  useEffect(() => {
    if (holdersData) {
      // Filter holders based on the search term
      const filtered = holdersData.filter((item: any) => {
        const fullName = `${item.investorDetails.firstName} ${item.investorDetails.lastName}`.toLowerCase();
        return fullName.includes(searchTerm.toLowerCase());
      });
      setFilteredData(filtered);
    }
  }, [holdersData, searchTerm]);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber < 1) return;
    if (pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  // eslint-disable-next-line
  const handleItemsPerPageChange = (item: number) => {
    setItemsPerPage(item);
    setCurrentPage(1); // Reset to first page on items per page change
  };

  return (
    <div className="min-h-screen flex flex-col">
      <div className="xl:relative bg-white rounded-lg p-4 text-[11px] xl:flex-grow">
        <div className="grid sm:grid-cols-2 justify-between items-center gap-4 sm:gap-0 mb-4">
          <h4>Holders List</h4>
          <div className="flex justify-end gap-4">
            <div className="relative w-[210px] sm:w-[230px] md:w-[300px]">
              <input
                type="text"
                placeholder="Search investors..."
                className="!w-full !border-none body-small-regular !text-[12px] !m-0 !bg-monochrome-5 !rounded-lg"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="absolute top-[9px] right-3 text-monochrome-20">
                <IcSearch />
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto lg:overflow-hidden h-full min-h-[calc(100vh-280px)]">
          <div className="w-[220%] lg:w-full">
            <div className="grid grid-cols-4 body-small-regular text-monochrome-30 py-3 border-b border-monochrome-10">
              <span className="text-start">Investor</span>
              <span className="text-start">Investor's Holdings</span>
              <span className="text-start">Amount</span>
              <span className="text-start">Percentage of Funding</span>
            </div>
            <div className="">
              {isLoading ? (
                <p>Loading...</p>
              ) : currentItems.length > 0 ? (
                currentItems.map((item, index) => (
                  <div key={index} className={`grid grid-cols-4 py-3 border-b border-monochrome-10 ${index === currentItems.length - 1 ? "mb-[55px]" : ""}`}>
                    <span className="text-monochrome-60 text-[0.75rem] text-start my-auto font-medium">
                      {item.investorDetails.firstName} {item.investorDetails.lastName}
                    </span>
                    <span className="text-monochrome-60 body-regular text-start my-auto">{item.totalAmount} tokens</span>
                    <span className="text-monochrome-60 body-regular text-start my-auto">{item.totalAmount * 500} USD</span>
                    <span className="text-monochrome-60 body-regular text-start my-auto">{((item.totalAmount * 500) / projectData.fundingGoal) * 100} %</span>
                  </div>
                ))
              ) : (
                <p className="text-monochrome-60 text-[0.75rem] text-center my-auto pt-4">No Token Holders.</p>
              )}
            </div>
            <div className="w-full xl:absolute bottom-4 right-0 text-[14px]">
              <div className="flex items-center justify-end gap-2 body-small-regular mr-4">
                <span className="text-monochrome-20">Page</span>
                <input
                  className="!bg-monochrome-5 !text-[12px] !text-monochrome-20 !w-[40px] text-center !py-1 !px-0 rounded-lg !m-0 !min-h-[auto] !border-0 p-2 rounded-l-lg !leading-[20px]"
                  type="text"
                  value={currentPage}
                  onChange={(e) => handlePageChange(Number(e.target.value))}
                />
                <span className="text-monochrome-20">of {totalPages}</span>
                <div className="grid grid-cols-2 border-2 border-monochrome-10 divide-monochrome-10 divide-x rounded-lg items-center">
                  <button className="p-2 bg-monochrome-5 rounded-l-lg" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    <IcPreviousPage />
                  </button>
                  <button className="p-2 bg-monochrome-5 rounded-r-lg" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                    <IcNextPage />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
