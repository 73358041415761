import { IcProjectCompleted, IcProjectInreview } from "@/assets/Icons";
import GetCapitalBanner from "@/components/Dashboard/GetCapitalBanner";
import ProjectOverViewCard from "@/components/Dashboard/ProjectOverViewCard";
import ProjectDashboardCard from "@/components/Dashboard/Projects";
import RecentTransactions from "@/components/Dashboard/RecentTransactions/RecentTransactions";
import WithdrawBalanceCard from "@/components/WalletBalanceCard";
import { ROUTES } from "@/constants";
import useAuth from "@/hooks/useAuth";
import { useNetwork } from "@/provider/NetworkContext";
import useStore from "@/store/useStore";
import { ITransaciton } from "@/types";
import BalanceCard from "@components/BalanceCard";
import Loader from "@components/Loader";
import { fetchCompany, fetchProjects, fetchTransactions, fetchUser } from "@services/api";
import { useQuery } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface ITransactionsProps {
  selectedTransaction: ITransaciton | null;
  setSelectedTransaction: Dispatch<SetStateAction<ITransaciton | null>>;
}
interface DashboardStates {
  inReviewProjectsCount: number;
  totalProjectsCount: number;
  totalFinancingReceived: number;
}

const DashboardPage = (props: ITransactionsProps) => {
  const { userId, companyId } = useAuth();
  const navigate = useNavigate();

  // eslint-disable-next-line
  const { setUser, setCompany, userData, companyData } = useStore();
  const [dashboardStates, setDashboardStates] = useState<DashboardStates>({
    inReviewProjectsCount: 0,
    totalProjectsCount: 0,
    totalFinancingReceived: 0,
  });
  const { selectedNetwork } = useNetwork();

  const { data: userDetails, isLoading: isLoadingUser } = useQuery({
    queryKey: ["getUser", userId],
    queryFn: () => fetchUser(userId),
    enabled: !!userId,
  });

  // eslint-disable-next-line
  const { data: transactions = [], isLoading: isLoadingTransactions } = useQuery({
    queryKey: ["getTransactions", companyId],
    queryFn: () => fetchTransactions(companyId, selectedNetwork),
    enabled: !!companyId,
    select: (data) => (data ? data.sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime()).slice(0, 5) : []),
  });

  const { data: companyDetails, isLoading: isLoadingCompany } = useQuery({
    queryKey: ["getCompany", companyId],
    queryFn: () => fetchCompany(companyId),
    enabled: !!companyId,
  });

  const { data: projectsData, isLoading: isLoadingProjects } = useQuery({
    queryKey: ["getProjects", companyId],
    queryFn: () => fetchProjects(companyId),
    enabled: !!companyId,
  });

  const isProfileExisting = userData.isProfileExisting || false;
  useEffect(() => {
    setTimeout(() => {
      if (!isProfileExisting) {
        navigate(ROUTES.PROFILE_SETUP);
      }
    }, 2000);
    // eslint-disable-next-line
  }, []);

  // Update user and company data in the store
  useEffect(() => {
    if (userDetails) {
      setUser(userDetails.data);
    }

    if (companyDetails) {
      const { projects, states, ...details } = companyDetails.data;
      setDashboardStates(states);
      setCompany(details);
    }
    // eslint-disable-next-line
  }, [userDetails, companyDetails, setUser, setCompany]);

  // Helper functions for data handling
  const REWARD_FROM_ADDRESS = "0xa7d36B87D814257ADCa79cFe521f2a3fdcDdfDDa".toLowerCase();
  const PENOMO_TOKEN_SYMBOL = "PENOMO";

  const getTransactionType = (transaction: any) => {
    const { from, tokenSymbol } = transaction;
    if (tokenSymbol === PENOMO_TOKEN_SYMBOL && from.toLowerCase() === REWARD_FROM_ADDRESS) {
      return "reward transaction";
    }
    return transaction.transactionType;
  };

  // eslint-disable-next-line
  const getCardClass = (transaction: any) => {
    const transactionType = getTransactionType(transaction);
    return transactionType === "reward transaction" ? "cards reward-transaction" : "cards";
  };

  // eslint-disable-next-line
  const getNotifications = () => {
    const notifications = [];

    if (userDetails?.data?.statusUpdates) {
      notifications.push(...userDetails.data.statusUpdates);
    }

    if (companyDetails?.data?.statusUpdates) {
      notifications.push(...companyDetails.data.statusUpdates);
    }

    if (projectsData) {
      projectsData.data.forEach((project: any) => {
        if (project.statusUpdates) {
          notifications.push(...project.statusUpdates);
        }
      });
    }

    return notifications;
  };

  // Loading state handling
  if (!userDetails || isLoadingUser || isLoadingCompany || isLoadingProjects) {
    return (
      <div className="center">
        <Loader isLoading={isLoadingUser || isLoadingCompany || isLoadingProjects} />
      </div>
    );
  }

  // Rendered UI with fetched data
  return (
    <div className="flex flex-col gap-4">
      <GetCapitalBanner />
      <section className="grid md:grid-cols-2 xl:grid-cols-4 gap-4 min-h-[145px]">
  <BalanceCard />
  <WithdrawBalanceCard />
  
  {/* <div className="grid grid-cols-1 md:grid-cols-2 xl:col-span-2 gap-4"> */}
    <ProjectOverViewCard icon={<IcProjectCompleted />} count={dashboardStates?.totalProjectsCount ?? 0} title="Projects Listed" />
    <ProjectOverViewCard icon={<IcProjectInreview />} count={dashboardStates?.inReviewProjectsCount ?? 0} title="Projects In Progress" />
  {/* </div> */}
</section>
      <section className="grid grid-cols-1 xl:grid-cols-2 gap-4">
        <ProjectDashboardCard />
        <RecentTransactions companyId={companyId} {...props} />
      </section>
    </div>
  );
};

export default DashboardPage;
