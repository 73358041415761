import { ProjectWarning } from '@/assets/Icons';
import Loader from "@/components/Loader";
import Button from '@/components/UI/Button';
import ProjectModal from '../Modal';

const SubmitModal = ({open, setOpen,handleCloseModal,handleSubmit,loading}:any) => {
    // eslint-disable-next-line
    const initialState = { submit: false, submitted: false, confirmTransaction: false, successConfirmTransaction: false };
  return (
    <div>
        <ProjectModal open={open} onClose={handleCloseModal}>
                <section className="p-4 flex flex-col gap-7 items-center">
                    <ProjectWarning />
                    <div className="flex flex-col gap-2 items-center w-full">
                        <h3>Are you sure you want to submit ?</h3>
                        <p className="text-sm w-[90%] text-center text-[#8E8E8E]">
                            Please double-check all information before proceeding
                        </p>
                    </div>
                    <div className={loading ? "flex justify-center items-center" : "flex items-center w-full gap-[10px]"}>
                        {loading ? (
                            <Loader isLoading={loading} />
                        ) : (
                            <>
                                <Button primary rounded classNames="w-full" onClick={handleSubmit}>
                                    Yes
                                </Button>
                                <Button primary rounded classNames="w-full" onClick={handleCloseModal}>
                                    No
                                </Button>
                            </>
                        )}
                    </div>
                </section>
            </ProjectModal>
      
    </div>
  )
}

export default SubmitModal
