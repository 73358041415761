import peaqLogo from "@/assets/peaq2_logo_white.svg";
import polygonLogo from "@/assets/polygon_logo_white.svg";
import Button from "@/components/UI/Button";
import Dropdown from "@/components/UI/Dropdown/Dropdown"; // Import Dropdown component
import { formatNumberWithCommas } from "@/utils/commanFunctions";
import { Modal } from "flowbite-react";
import { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

// Document info mapping
// eslint-disable-next-line
const GET_LISTED_INFO: { [key: string]: string } = {
  Platform: "Platform",
  "Token price [$]": "Token price [$]",
  "Token Amount": "Token Amount",
  "Start of Subscription": "Start of Subscription",
  "End of Subscription": "End of Subscription",
  "Target Issuance Date": "Target Issuance Date",
  "Select Network": "Select Network",
  "Issuance Type": "Issuance Type",
  "Allowed Investors": "Allowed Investors",
};

// Zod validation schema
const schema = z.object({
  startOfSubscription: z.string().min(1, "Start of Subscription is required"),
  endOfSubscription: z.string().min(1, "End of Subscription is required"),
  targetIssuanceDate: z.string().min(1, "Target Issuance Date is required"),
  selectedNetwork: z.string().min(1, "Network is required"),
  selectedIssuanceType: z.string().min(1, "Issuance Type is required"),
  allowedInvestorType: z.string().min(1, "Select Type of Investor"),
});

// Network options for dropdown
const networkOptions = [
  {
    value: "peaq",
    label: "peaq",
    icon: peaqLogo,
  },
  {
    value: "polygon",
    label: "Polygon",
    icon: polygonLogo,
  },
];

const issuanceOption = [
  {
    value: "public",
    label: "Public",
  },
  {
    value: "private",
    label: "Private",
  },
];

// Allowed Investor Types
export const investorTypes = ["Retail", "Accredited"];

// Helper function to format date as dd/mm/yyyy
// eslint-disable-next-line
const formatDateToDDMMYYYY = (dateString: string) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

// Helper function to convert back to yyyy-mm-dd format for form inputs
const formatDateToInputValue = (dateString: string) => {
  if (!dateString) return "";
  return new Date(dateString).toLocaleDateString("en-GB").split("/").reverse().join("-");
};

const CustomTooltip = ({ content }: { content: string }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="relative inline-block">
      <FaInfoCircle className="text-xs text-gray-400 cursor-pointer ml-4" onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)} />
      {isVisible && (
        <div className="absolute z-10 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700 bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-48">
          {content}
          <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
      )}
    </div>
  );
};

export default function GetListed({ onListProjectClick, projectData, loading }: any) {
  const [startOfSubscription, setStartOfSubscription] = useState(formatDateToInputValue(projectData?.startOfSubscription));
  const [endOfSubscription, setEndOfSubscription] = useState(formatDateToInputValue(projectData?.endOfSubscription));
  const [targetIssuanceDate, setTargetIssuanceDate] = useState(formatDateToInputValue(projectData?.targetIssuanceDate));
  const [selectedNetwork, setSelectedNetwork] = useState(projectData?.blockchainForTokenization || networkOptions[0].value);
  const [selectedIssuanceType, setSelectedIssuanceType] = useState(projectData?.issuanceType);
  const [allowedInvestorType, setAllowedInvestorType] = useState(projectData?.allowedInvestorType);
  const [errors, setErrors] = useState<any>({});
  const [isSubmitted, setIsSubmitted] = useState(false); // New state to track form submission
  const [showBankModal, setShowBankModal] = useState(false);
  const navigate = useNavigate();

  // Function to check if bank details are complete
  const areBankDetailsComplete = () => {
    return !!(
      projectData?.companyId?.bankAccountHolderName &&
      projectData?.companyId?.bankAccountNumber &&
      projectData?.companyId?.bankDefaultCurrency &&
      projectData?.companyId?.bankIban &&
      projectData?.companyId?.bankName &&
      projectData?.companyId?.bankSwiftBicCode
    );
  };

  // Handle form submission and validation
  const handleListProject = () => {
    const formData = {
      startOfSubscription,
      endOfSubscription,
      targetIssuanceDate,
      selectedNetwork,
      selectedIssuanceType,
      allowedInvestorType,
    };

    // Validate the form data using Zod
    const validation = schema.safeParse(formData);

    if (!validation.success) {
      // If validation fails, set the error messages
      const errorObject = validation.error.format();
      setErrors({
        startOfSubscription: errorObject.startOfSubscription?._errors[0],
        endOfSubscription: errorObject.endOfSubscription?._errors[0],
        targetIssuanceDate: errorObject.targetIssuanceDate?._errors[0],
        selectedNetwork: errorObject.selectedNetwork?._errors[0],
        selectedIssuanceType: errorObject.selectedIssuanceType?._errors[0],
        allowedInvestorType: errorObject.allowedInvestorType?._errors[0],
      });
    } else {
      // If validation passes, check for bank details
      if (!areBankDetailsComplete()) {
        setShowBankModal(true);
      } else {
        // If bank details are complete, proceed with the listing request
        setErrors({});
        onListProjectClick(formData);
        setIsSubmitted(true);
      }
    }
  };

  const handleBankDetailClick = () => {
    navigate("/settings", { state: { activateBankDetails: true } });
  };

  const handleFieldChange = (fieldName: string, value: string) => {
    setErrors((prevErrors: any) => ({ ...prevErrors, [fieldName]: undefined }));
  };

  const LabelWithTooltip = ({ label, tooltip }: { label: string; tooltip: string }) => (
    <div className="label flex items-center">
      {label}
      <CustomTooltip content={tooltip} />
    </div>
  );

  return (
    <div className="flex">
      <div className="bg-white shadow-md rounded-lg p-6 w-full">
        <h2 className="text-xl font-semibold mb-4">Get Listed</h2>
        <p className="text-[12px] text-[#8E8E8E] mb-4">Choose a platform, set your price, and network to start selling your tokens.</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 items-center justify-between">
          <div className="field">
            <div className="label flex  items-center">
              <LabelWithTooltip label="Platform" tooltip="Platform" />
            </div>
            <input
              type="text"
              value={"penomo marketplace"}
              className="!border-t-[0] !border-l-[0] !border-r-[0] border-b !border-[#DFDFDF] !rounded-none px-0 w-full text-gray-700 text-sm"
              disabled
            />
          </div>
          <div className="field">
            <div className="label flex items-center">
              <LabelWithTooltip label="Token price [$]" tooltip="Token price [$]" />
            </div>
            <input
              type="text"
              className="!border-t-[0] !border-l-[0] !border-r-[0] border-b !border-[#DFDFDF] !rounded-none px-0 w-full text-gray-700 text-sm"
              value={formatNumberWithCommas(String(projectData?.tokenPrice))}
              disabled
            />
          </div>
          <div className="field">
            <div className="label flex items-center">
              <LabelWithTooltip label="Token Amount" tooltip="Token Amount" />
            </div>
            <input
              type="text"
              className="!border-t-[0] !border-l-[0] !border-r-[0] border-b !border-[#DFDFDF] !rounded-none px-0 w-full text-gray-700 text-sm"
              value={formatNumberWithCommas(String(projectData?.tokenAmount))}
              disabled
            />
          </div>

          {/* Start of Subscription */}
          <div className="field">
            <div className="label flex items-center">
              <LabelWithTooltip label="Start of Subscription" tooltip="Start of Subscription" />
            </div>
            <input
              type="date"
              value={startOfSubscription}
              // onChange={(e) => setStartOfSubscription(e.target.value)}
              onChange={(e) => {
                setStartOfSubscription(e.target.value);
                handleFieldChange("startOfSubscription", e.target.value);
              }}
              className="!border-t-[0] !border-l-[0] !border-r-[0] border-b !border-[#DFDFDF] !rounded-none px-0 w-full text-gray-700 text-sm"
              disabled={isSubmitted || projectData?.isInReviewForListing}
            />
            {errors.startOfSubscription && <p className="text-red-500 error text-xs mt-1">{errors.startOfSubscription}</p>}
          </div>

          {/* End of Subscription */}
          <div className="field">
            <div className="label flex items-center">
              <LabelWithTooltip label="End of Subscription" tooltip="End of Subscription" />
            </div>
            <input
              type="date"
              value={endOfSubscription}
              // onChange={(e) => setEndOfSubscription(e.target.value)}
              onChange={(e) => {
                setEndOfSubscription(e.target.value);
                handleFieldChange("endOfSubscription", e.target.value);
              }}
              className="!border-t-[0] !border-l-[0] !border-r-[0] border-b !border-[#DFDFDF] !rounded-none px-0 w-full text-gray-700 text-sm"
              disabled={isSubmitted || projectData?.isInReviewForListing}
            />
            {errors.endOfSubscription && <p className="text-red-500 error text-xs mt-1">{errors.endOfSubscription}</p>}
          </div>

          {/* Target Issuance Date */}
          <div className="field">
            <div className="label flex items-center">
              <LabelWithTooltip label="Target Issuance Date" tooltip="Target Issuance Date" />
            </div>
            <input
              type="date"
              value={targetIssuanceDate}
              // onChange={(e) => setTargetIssuanceDate(e.target.value)}
              onChange={(e) => {
                setTargetIssuanceDate(e.target.value);
                handleFieldChange("targetIssuanceDate", e.target.value);
              }}
              className="!border-t-[0] !border-l-[0] !border-r-[0] border-b !border-[#DFDFDF] !rounded-none px-0 w-full text-gray-700 text-sm"
              disabled={isSubmitted || projectData?.isInReviewForListing}
            />
            {errors.targetIssuanceDate && <p className="text-red-500 error text-xs mt-1">{errors.targetIssuanceDate}</p>}
          </div>

          {/* Network Selection Dropdown */}
          <div className="field">
            <div className="label flex items-center">
              <LabelWithTooltip label="Select Network" tooltip="Select Network" />
            </div>
            <Dropdown
              icon={<img src={selectedNetwork === "polygon" ? networkOptions[1].icon : networkOptions[0].icon} alt="network icon" />}
              value={selectedNetwork}
              className="w-full h-[38px] rounded"
              onChange={(selectedValue: any) => setSelectedNetwork(selectedValue.value)}
              options={networkOptions}
              disabled={isSubmitted || projectData?.isInReviewForListing} // Disable after submission
            />
            {errors.selectedNetwork && <p className="text-red-500 error text-xs mt-1">{errors.selectedNetwork}</p>}
          </div>

          {/* Issuance Type Dropdown */}
          <div className="field">
            <div className="label flex items-center">
              <LabelWithTooltip label="Issuance Type" tooltip="Issuance Type" />
            </div>
            <Dropdown
              value={selectedIssuanceType}
              className="w-full h-[38px] rounded"
              // onChange={(selectedValue: any) => setSelectedIssuanceType(selectedValue.value)}
              menuBoxMarginTop={5}
              onChange={(option) => {
                setSelectedIssuanceType(option.value);
                handleFieldChange("selectedIssuanceType", option.value);
              }}
              options={issuanceOption}
              disabled={isSubmitted || projectData?.isInReviewForListing} // Disable after submission
            />
            {errors.selectedIssuanceType && <p className="text-red-500 error text-xs mt-1">{errors.selectedIssuanceType}</p>}
          </div>

          {/* Allowed Investor Type Dropdown */}
          {selectedIssuanceType === "public" && (
            <div className="field">
              <div className="label flex items-center">
                <LabelWithTooltip label="Allowed Investors" tooltip="Allowed Investors" />
              </div>
              <Dropdown
                value={allowedInvestorType}
                className="w-full h-[38px] rounded"
                onChange={(selectedValue: any) => setAllowedInvestorType(selectedValue.value)}
                options={investorTypes.map((type) => ({ value: type, label: type }))}
                disabled={isSubmitted || projectData?.isInReviewForListing}
              />
              {errors.allowedInvestorType && <p className="text-red-500 error text-xs mt-1">{errors.allowedInvestorType}</p>}
            </div>
          )}
        </div>

        <div className="flex items-center justify-center">
          {/* If project is in review for listing, disable the button and change text */}
          {projectData?.isInReviewForListing ? (
            <Button secondary rounded disabled>
              Requested for Listing
            </Button>
          ) : (
            <Button secondary rounded onClick={handleListProject} disabled={loading || isSubmitted}>
              {loading ? "Listing..." : "Request Listing of Project"}
            </Button>
          )}
        </div>
      </div>
      <Modal show={showBankModal} onClose={() => setShowBankModal(false)}>
        <Modal.Header>Bank Details Required</Modal.Header>
        <Modal.Body>
          <div className="p-4">
            <p>Please enter your bank details before proceeding with the listing request.</p>
            <p>You can Fill it in the Settings Page under Bank Details section</p>
            <ul className="list-disc pl-5 mt-2">
              <li>Bank Account Holder Name</li>
              <li>Bank Account Number</li>
              <li>Bank Default Currency</li>
              <li>Bank IBAN</li>
              <li>Bank Name</li>
              <li>Bank SWIFT/BIC Code</li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex flex-col md:flex-row gap-2 ">
            <Button secondary rounded onClick={() => setShowBankModal(false)}>
              Close
            </Button>
            <Button secondary rounded onClick={handleBankDetailClick}>
              Complete Bank Details
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
