import { useNavigate } from "react-router-dom"; // Add this import
import Loader from "@/components/Loader";
import Button from "@/components/UI/Button";
import { getAllPurchaseRequests } from "@/services/api";
import { useQuery } from "@tanstack/react-query";
import InvestorPurchaseItems from "./InvestorPurchaseItems";
import toast from "react-hot-toast";

const InvestorPurchaseCard = ({ projectData }: any) => {
    console.log("🚀 ~ InvestorPurchaseCard ~ projectData:", projectData)
    const navigate = useNavigate(); // Initialize navigate

    const tokenId = projectData?.tokenIds[0]?._id;

    const { data: purchaseData, isLoading } = useQuery({
        queryKey: ["getAllPurchaseRequests", tokenId],
        queryFn: () => getAllPurchaseRequests(tokenId),
        enabled: !!tokenId,
        select: (res) => res.data,
    });

    const handleRedirectProjects = () => {
        if (projectData.status === "Listed") {

            // Append `tab` and `subTab` query params for navigation
            const projectId = projectData?._id || "";
            navigate(`/projects/overview?projectId=${projectId}&tab=Token Management&subTab=purchaseRequest`);
        }else{
            toast.error('Your Project is not Listed')
        }
    };

    return (
        <div className="bg-white p-4 flex flex-col rounded-lg">
            <div className="flex justify-between items-center mb-4">
                <h5 className="font-medium text-monochrome-100 text-xl">Last Investor Purchase Requests</h5>
                <Button
                    transparent
                    classNames="cursor-pointer text-monochrome-50 justify-end body-regular pr-0 hover:bg-transparent"
                    onClick={handleRedirectProjects}
                >
                    View All
                </Button>
            </div>
            <div className="overflow-x-auto sm:overflow-hidden">
                <div className="w-[150%] sm:w-full">
                    <div className="grid grid-cols-4 text-monochrome-30 body-small-regular py-3 border-b border-monochrome-10">
                        <span className="text-start">Investor</span>
                        <span className="text-start">Total Price</span>
                        <span className="text-start">Token Amount</span>
                        <span className="text-start">Status</span>
                    </div>
                    {isLoading && (
                        <div className="center min-h-20">
                            <Loader isLoading={true} />
                        </div>
                    )}
                    <div className="max-h-[calc(5*3.5rem)] overflow-y-auto">
                        {purchaseData?.map((item: any, index: number) => (
                            <InvestorPurchaseItems key={`project-item-${index}`} item={item} />
                        ))}
                    </div>
                    {!isLoading && (!purchaseData || purchaseData.length === 0) && (
                        <div className="center min-h-40">No purchase requests</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default InvestorPurchaseCard;
