import React from "react";
import Select, { components } from "react-select";
import { DropdownProps, Option } from "./DropdownProps";

// eslint-disable-next-line
const { Control, Option: DefaultOption } = components;

const StyledDropdown: React.FC<DropdownProps> = ({
  options,
  onChange,
  icon,
  value,
  disabled,
  className,
  height,
  menuBoxMarginTop = 4,
  isSearchable = false,
  isMulti = false,
}) => {
  const handleChange = (selectedOption: Option | Option[] | null) => {
    if (isMulti) {
      onChange(selectedOption as Option[]);
    } else {
      onChange(selectedOption as Option | null);
    }
  };

  const CustomValueContainer = ({ children, ...props }) => {
    const { getValue } = props;
    const selectedValues = getValue();

    let displayLabel = "";
    if (selectedValues.length > 0) {
      displayLabel = `${selectedValues.length} selected`;
    }

    return <components.ValueContainer {...props}>{displayLabel || children}</components.ValueContainer>;
  };

  return (
    <Select
      options={options}
      className={`${className} transactions-project-category`}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          border: "none",
          boxShadow: "none",
          borderColor: "transparent",
          height: height,
          background: "white",
          borderRadius: "6px",
          color: "black",
          fontSize: "14px",
          width: "300px",
        }),
        menu: (provided) => ({
          ...provided,
          marginTop: menuBoxMarginTop + "px",
          backgroundColor: "white",
          borderRadius: "10px",
          boxShadow: "none",
          border: "1px solid #dfdfdf",
        }),
      }}
      value={isMulti ? (value as Option[] | null) : options?.find((option) => option.value === value)}
      components={{
        IndicatorSeparator: () => null,
        Control: ({ children, ...props }) => {
          // eslint-disable-next-line
          const selectedOptions = props.getValue();

          return (
            <Control
              className="flex items-center gap-2 !w-full p-[3px] !border-2 !bg-[#ffffff] !border-transparent text-sm select-dropdown transactions-dropdown focus:border-monochrome-40"
              {...props}
            >
              {icon}
              {children}
            </Control>
          );
        },
        ValueContainer: CustomValueContainer,
        ClearIndicator: () => null,
        Option: ({ innerProps, label, data, isSelected }) => {
          return (
            <div className={`flex items-center justify-between gap-2 h-10 p-4 text-black cursor-pointer hover:bg-monochrome-10 group`} {...innerProps}>
              <div className="flex items-center gap-2">
                <div
                  className={`w-4 h-4 border-2 rounded-sm flex items-center justify-center ${
                    isSelected ? "bg-green-500 border-green-500" : "border-monochrome-10 group-hover:border-black"
                  }`}
                >
                  {isSelected && <span className="text-white">✓</span>}
                </div>
                <span className="text-sm">{label}</span>
              </div>
            </div>
          );
        },
      }}
      isSearchable={isSearchable}
      onChange={handleChange}
      isDisabled={disabled}
      isMulti={isMulti}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
    />
  );
};

export default StyledDropdown;
