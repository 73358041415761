import peaqLogo from "@/assets/peaq2_logo_white.svg";
import polygonIcon from "@/assets/polygon_logo_white.svg";
import { ROUTES } from "@/constants";
import { NavBarList } from "@/constants/NavMenu";
import useAuth from "@/hooks/useAuth";
import { useNetwork } from "@/provider/NetworkContext";
import { AuthContext } from "@/services/AuthContext";
import { classNames } from "@/utils";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import NotificationCard from "./NotificationsCard";
import Button from "./UI/Button";
import Dropdown from "./UI/Dropdown/Dropdown";

const networkOptions = [
  {
    value: "peaq",
    label: "PEAQ",
    icon: peaqLogo,
  },
  {
    value: "polygon",
    label: "Polygon",
    icon: polygonIcon,
  },
];

const Header = () => {
  const { pathname } = useLocation();
  const { userId } = useAuth();
  const { selectedNetwork, setSelectedNetwork } = useNetwork();
  const { switchNetwork } = useContext(AuthContext);
  // eslint-disable-next-line
  const [pendingNetwork, setPendingNetwork] = useState<string>("");
  // eslint-disable-next-line
  const [showNotificationCard, setShowNotificationCard] = useState(false); // State for NotificationCard visibility

  if (!userId) {
    return null;
  }
  const isSetupProfilePage = pathname === ROUTES.PROFILE_SETUP;
  const isProjectOverviewPage = pathname === ROUTES.OVERVIEW;

  const handleNetworkChange = async (network: string) => {
    setPendingNetwork(network);
    try {
      await switchNetwork(); // Switching the network
      setSelectedNetwork(network); // Set the new selected network

      // Show toast with "Infinity" duration (stays until the user clicks "X")
      // toast.success(`Network switched to ${network} successfully!`, {
      //   // duration: Infinity, // Make the toast persistent
      //   duration: 3000,
      //   position: "top-right", // You can customize the position
      // });
    } catch (error) {
      console.error("Failed to switch network:", error);
      toast.error("Failed to switch network. Please try again.", {
        duration: Infinity, // Persistent error toast
        position: "top-right",
      });
    }
  };

  if (userId && isSetupProfilePage) {
    return null;
  }

  const onClickBack = () => {
    window.history.back();
  };

  // eslint-disable-next-line
  const handleNotificationClick = () => {
    setShowNotificationCard((prev) => !prev);
  };

  return (
    <>
      <header className={classNames("h-16 px-3 py-3 sm:py-0 bg-monochrome-5 flex justify-between items-center gap-y-2 sticky top-0 z-20", { hidden: !userId })}>
        <h3 className="my-auto">
          {isProjectOverviewPage && (
            <div className="flex items-center gap-2">
              <Button classNames="bg-white h-fit w-fit !p-[10px] leading-[10px]" rounded onClick={onClickBack}>
                {"<"}
              </Button>
              <p className="text-xs font-normal">Go Back</p>
            </div>
          )}
          {
            NavBarList.find((item: any) => {
              return item.link === pathname;
            })?.title
          }
          {pathname === "/projects/getCapital" && "Get Capital"}
        </h3>
        <div className="flex items-center gap-x-6 pr-1 z-50">
          <Dropdown
            icon={<img src={selectedNetwork === "polygon" ? networkOptions[1].icon : networkOptions[0].icon} alt="network icon" />}
            value={selectedNetwork}
            className="w-full md:w-[160px] h-[38px] bg-white rounded pl-3"
            onChange={(selectedValue: any) => handleNetworkChange(selectedValue.value)}
            options={networkOptions}
          />
        </div>
      </header>

      {/* {showNotificationCard && */}
      <NotificationCard onClose={() => setShowNotificationCard(false)} />
      {/* } */}
    </>
  );
};

export default Header;
