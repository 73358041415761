import React, { useEffect, useState } from "react";
import { defaultState, defaultTypeOfRevenue } from "../DetailConstant";
import { formatNumberWithCommas, removeCommas } from "@/utils/commanFunctions";
import Switch from "react-switch";
import Dropdown from "@/components/UI/Dropdown/Dropdown";
import Button from "@/components/UI/Button";
import Loader from "@/components/Loader";
import { updateProjectDetails } from "@/services/api";
import ConfirmationModal from "../ConfirmationModal";
import { FaInfoCircle } from "react-icons/fa";

type DefaultStateKeys = keyof typeof defaultState;

const CustomTooltip = ({ content }: { content: string }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="relative inline-block">
      <FaInfoCircle className="text-gray-400 cursor-pointer ml-1" onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)} />
      {isVisible && (
        <div className="min-w-[200px] absolute z-10 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700 bottom-full left-10 -translate-x-1/2 mb-2 ">
          {content}
          <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
      )}
    </div>
  );
};

const LabelWithTooltip = ({ label, tooltip }: { label: string; tooltip: string }) => (
  <div className="label flex items-center">
    {label}
    <CustomTooltip content={tooltip} />
  </div>
);

const ValuationDetail = ({ data, refetch, isInReviewForListing }: any) => {
  const {
    revenueStreams = [],
    commercialOperationDate = "",
    totalInitialCost = "",
    operationLifetime = "",
    financingStructure = "",
    ownershipStructure = "",
    annualOperationCosts = "",
    irr = "",
    npv = "",
    lcoe = "",
    capacityFactor = "",
    paybackPeriod = "",
    hasRevenueGeneratingAssets = false,
  } = { ...data };
  const [details, setDetail] = useState({
    general: {
      hasRevenueGeneratingAssets: false,
    },
    valuation: {
      npv: "",
      irr: "",
      lcoe: "",
      paybackPeriod: "",
      capacityFactor: "",
      totalInitialCost: "",
      operationLifetime: "",
      financingStructure: "",
      ownershipStructure: "",
      commercialOperationDate: "",
      annualOperationCosts: "",
    },
  });
  const [isEditMode, setIsEditMode] = useState({
    valuation: false,
  });
  const todayDate: any = new Date().toISOString().split("T")[0];
  const [isUpdating, setUpdating] = useState(false);
  const [typeOfRevenue, setTypeOfRevenue] = useState(defaultTypeOfRevenue);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    setDetail({
      general: {
        hasRevenueGeneratingAssets,
      },
      valuation: {
        npv,
        irr,
        lcoe,
        paybackPeriod,
        capacityFactor,
        totalInitialCost,
        operationLifetime,
        financingStructure,
        ownershipStructure,
        commercialOperationDate,
        annualOperationCosts,
      },
    });

    setTypeOfRevenue((prevState: any) => {
      return prevState.map((item: any) => {
        const hasStream = revenueStreams.find((ele: any) => ele.type === item.value);
        if (hasStream) {
          return {
            ...item,
            name: hasStream.name,
            status: true,
            yearlyOrMonthly: hasStream.monthlyRevenue ? "0" : "1",
            projectedRevenue: hasStream.monthlyRevenue || hasStream.yearlyRevenue,
          };
        }
        return item;
      });
    });
    // eslint-disable-next-line
  }, [data]);

  const handleChangeDetails = async (evt: any, changingTo: any, isFundingUpdateIndex?: number) => {
    const { name, value } = evt.target;

    // List of names to check against
    const namesToCheck = [
      "operationLifetime",
      "totalInitialCost",
      "annualOperationCosts",
      "npv",
      "irr",
      "lcoe",
      "paybackPeriod",
      "capacityFactor",
      "fundingGoal",
      "tokenPrice",
      "tokenAmount",
      "contractTerm",
      "amount",
    ];

    // Replace commas only if the name is in the list
    const processedValue = namesToCheck.includes(name) && typeof value === "string" ? removeCommas(value) : value;

    if (!isUpdating) {
      if (isFundingUpdateIndex !== undefined) {
        setDetail((prevState: any) => {
          let newUpdatedValue = [...prevState.financing.fundingUsage];
          newUpdatedValue[isFundingUpdateIndex] = { ...newUpdatedValue[isFundingUpdateIndex], [name]: processedValue };
          return {
            ...prevState,
            [changingTo]: {
              ...prevState[changingTo],
              fundingUsage: newUpdatedValue,
            },
          };
        });
        return;
      }
      setDetail((prevState: any) => {
        return {
          ...prevState,
          [changingTo]: {
            ...prevState[changingTo],
            [name]: processedValue,
          },
        };
      });
    }
  };
  const handleTypeOfRevenueChange = (index: number, field: string, value: any) => {
    if (field === "projectedRevenue") {
      value = formatNumberWithCommas(value);
    }

    if (field === "status" && value === false) {
      setTypeOfRevenue((prevTypeOfRevenue: any) => {
        const newTypeOfRevenue: any = [...prevTypeOfRevenue];
        newTypeOfRevenue[index].status = value;
        newTypeOfRevenue[index].projectedRevenue = "";
        newTypeOfRevenue[index].yearlyOrMonthly = "";
        if (index === newTypeOfRevenue.length - 1) {
          newTypeOfRevenue[index].name = "";
        }
        return newTypeOfRevenue;
      });
    } else {
      setTypeOfRevenue((prevTypeOfRevenue: any) => {
        const newTypeOfRevenue: any = [...prevTypeOfRevenue];
        newTypeOfRevenue[index][field] = value;
        return newTypeOfRevenue;
      });
    }
  };

  const handleClickCancleOrEdit = async (clickedTo: DefaultStateKeys) => {
    setIsEditMode((prevState: any) => ({ ...prevState, [clickedTo]: !prevState[clickedTo] }));

    const valuationPrevState = {
      npv,
      irr,
      lcoe,
      paybackPeriod,
      capacityFactor,
      totalInitialCost,
      operationLifetime,
      financingStructure,
      ownershipStructure,
      commercialOperationDate,
      annualOperationCosts,
    };

    setDetail((prevState: any) => {
      return {
        ...prevState,
        [clickedTo]: {
          ...(clickedTo === "valuation" ? valuationPrevState : data[clickedTo] ? data[clickedTo] : defaultState[clickedTo]),
        },
      };
    });
    setTypeOfRevenue(() => {
      return defaultTypeOfRevenue.map((item: any) => {
        const hasStream = revenueStreams.find((ele: any) => ele.type === item.value);
        if (hasStream) {
          return {
            ...item,
            name: hasStream.name,
            status: true,
            yearlyOrMonthly: hasStream.monthlyRevenue ? "0" : "1",
            projectedRevenue: hasStream.monthlyRevenue || hasStream.yearlyRevenue,
          };
        }
        return { ...item, status: false };
      });
    });
  };

  const handleUpdateDetails = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault();
    }

    setUpdating(true);
    const selectedRevenueList = typeOfRevenue.filter((item: any) => item.status);

    let updatedRevenueList = [];

    if (isEditMode.valuation) {
      updatedRevenueList = selectedRevenueList.map((item: any) => {
        const hasAlready = revenueStreams.find((ele: any) => ele.type === item.value);
        if (hasAlready) {
          return {
            ...hasAlready,
            name: item.name,
            yearlyRevenue:
              item.yearlyOrMonthly === "1"
                ? typeof item.projectedRevenue === "number"
                  ? item.projectedRevenue
                  : parseFloat(item.projectedRevenue.replace(",", ""))
                : 0,
            monthlyRevenue:
              item.yearlyOrMonthly === "0"
                ? typeof item.projectedRevenue === "number"
                  ? item.projectedRevenue
                  : parseFloat(item.projectedRevenue.replace(",", ""))
                : 0,
          };
        }
        return {
          name: item.name,
          type: item.value,
          yearlyRevenue: item.yearlyOrMonthly === "1" ? parseFloat(item.projectedRevenue.replace(",", "")) : 0,
          monthlyRevenue: item.yearlyOrMonthly === "0" || !item.yearlyOrMonthly ? parseFloat(item.projectedRevenue.replace(",", "")) : 0,
        };
      });
    } else {
      updatedRevenueList = revenueStreams;
    }

    const updatedDetails = { ...data, revenueStreams: updatedRevenueList, ...details["valuation"] };
    await updateProjectDetails(data._id, updatedDetails);
    await refetch();
    setUpdating(false);
    setShowConfirmModal(false);
  };

  const handleSaveClick = () => {
    setShowConfirmModal(true);
  };

  return (
    <>
      <div className="general-card-section">
        <h2 className="text-[14px]">Valuation</h2>
        <p className="text-[11px] ">Detailed Financials & Performance</p>
      </div>
      <div className="vertical-line"></div>
      <div className="general-card-section text-[11px]">
        <div className="field w-[100%] sm:w-[50%]">
          {/* <div className="label">
              {details.general.hasRevenueGeneratingAssets ? "Since when is it generating revenue ? " : "When will it generate revenue ?"}
            </div> */}
          <LabelWithTooltip
            label={details.general.hasRevenueGeneratingAssets ? "Since when is it generating revenue ? " : "When will it generate revenue ?"}
            tooltip="Select the type of asset that generates revenue for loan repayment"
          />
          <input
            disabled={!isEditMode.valuation}
            value={details.valuation.commercialOperationDate?.toString()?.slice(0, 10)}
            type="date"
            name="commercialOperationDate"
            onChange={(evt) => handleChangeDetails(evt, "valuation")}
            placeholder="10/08/2024"
            className={`${isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
            max={details.general.hasRevenueGeneratingAssets ? todayDate : undefined}
            min={!details.general.hasRevenueGeneratingAssets ? todayDate : undefined}
          />
        </div>
        {isEditMode.valuation ? (
          <div className="my-6">
            <div className="hidden xl:block ">
              <div className="grid grid-cols-3 mb-4 gap-6">
                <LabelWithTooltip label="Type of revenue" tooltip="Select the type of revenue stream for the asset" />
                {/* <span className="w-[20%]"></span> */}

                <LabelWithTooltip label="Estimated Revenue" tooltip="Enter the estimated revenue for this stream" />
                <LabelWithTooltip label="Calculation period" tooltip="Choose whether the revenue is calculated monthly or yearly" />
              </div>
            </div>

            <div className={` `}>
              {typeOfRevenue.map((item: any, index: any) => (
                <div
                  className={`${
                    isEditMode.valuation ? "w-full grid sm:grid-cols-1 xl:grid-cols-3 lg:gap-6 " : ""
                  } flex flex-col lg:flex-row justify-between  lg:items-center p-3 xl:p-0 rounded-lg bg-[#FCFCFC] xl:bg-white`}
                  key={index}
                >
                  <div className="flex flex-row w-full gap-4 text-nowrap items-center text-monochrome-60">
                    <Switch
                      offColor="#fff"
                      onColor="#00cc9c"
                      activeBoxShadow={""}
                      offHandleColor={"#9E9E9E"}
                      onHandleColor={"#fff"}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      handleDiameter={18}
                      borderRadius={9}
                      className={`border-2 rounded-[100px] ${item.status ? "border-green-500" : "border-monochrome-10"}`}
                      onChange={() => handleTypeOfRevenueChange(index, "status", !item.status)}
                      checked={item.status}
                    />
                    <div className="w-full lg:w-[20%]">
                      {index !== typeOfRevenue.length - 1 ? (
                        // <p>{item.name}</p>
                        <LabelWithTooltip label={item.name} tooltip={item.tooltipInfo} />
                      ) : (
                        <div>
                          <input
                            type="text"
                            tabIndex={0}
                            name="capitalNeeded"
                            disabled={!item.status}
                            className={`border-monochrome-10 !rounded-lg`}
                            value={item.name}
                            onChange={(e) => {
                              let value = e.target.value;
                              // value = value.replace(/[^A-Za-z]/gi, "");
                              handleTypeOfRevenueChange(index, "name", value);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Projected Revenue */}
                  <div className="relative w-full py-2 lg:py-0 ">
                    <label className={`${isEditMode.valuation ? "2xl:h-auto h-auto  items-start flex" : ""} xl:hidden`}>Revenue?</label>
                    <input
                      type="text"
                      name="projectedRevenue"
                      className={`mt-2 xl:mt-0 w-full pl-6  ${!item.status ? "border-monochrome-5" : "border-monochrome-10"} !rounded-lg `}
                      disabled={!item.status}
                      value={formatNumberWithCommas(String(item.projectedRevenue))}
                      onChange={(e) => handleTypeOfRevenueChange(index, "projectedRevenue", e.target.value)}
                    />
                    <p className={`absolute bottom-4 lg:bottom-2 left-2 ${!item.status ? "text-monochrome-20" : "text-monochrome-60"}`}>$</p>
                    <p className={`absolute bottom-4 lg:bottom-2 right-2 ${!item.status ? "text-monochrome-20" : "text-monochrome-60"}`}>USD</p>
                  </div>
                  {/* Projected Revenue / Yearly Revenue */}
                  <div className="w-full py-0 lg:py-2">
                    <label className={`${isEditMode.valuation ? "2xl:h-auto h-auto  items-start flex" : ""} xl:hidden`}>Projected yearly revenue?</label>
                    <Dropdown
                      menuBoxMarginTop={5}
                      value={item.yearlyOrMonthly}
                      className={`mt-2 xl:mt-0 px-0 rounded-lg  border border-monochrome-10 h-[42px]`}
                      onChange={(val) => handleTypeOfRevenueChange(index, "yearlyOrMonthly", val.value)}
                      options={[
                        // { value: "0", label: "Month" },
                        { value: "1", label: "Year" },
                      ]}
                      disabled={!item.status}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="flex  items-start space-x-2 justify-between">
            <div className="field w-[20%]">
              {/* <div className="label">Type of revenue</div> */}
              <LabelWithTooltip label="Type of revenue" tooltip="Select the type of revenue stream for the asset" />
              {revenueStreams.map((item: any, index: number) => (
                <div className="value py-2 break-words" key={index}>
                  {item.type}
                </div>
              ))}
            </div>
            <div className="field xl:w-[30%] w-[25%]">
              <div className="label">Revenue?</div>
              {revenueStreams.map((item: any, index: number) => (
                <input
                  key={index}
                  readOnly
                  value={
                    item.monthlyRevenue
                      ? `$  ${formatNumberWithCommas(String(item.monthlyRevenue))} USD`
                      : `$  ${formatNumberWithCommas(String(item.yearlyRevenue))} USD`
                  }
                  type="text"
                  disabled
                  placeholder="$  1,300,000  USD"
                  className={`${
                    isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                  } px-0 w-full text-ternary text-sm`}
                />
              ))}
            </div>
            <div className="field xl:w-[50%] md:w-[48%] w-[60%]">
              <div className="label">Projected yearly revenue?</div>
              {revenueStreams.map((item: any, index: number) => (
                <input
                  key={index}
                  readOnly
                  value={item.monthlyRevenue ? `per month` : `per year`}
                  type="text"
                  disabled
                  placeholder="$  1,300,000  USD"
                  className={`${
                    isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"
                  } px-0 w-full text-ternary text-sm`}
                />
              ))}
            </div>
          </div>
        )}

        <div className="flex flex-wrap items-center justify-between">
          <div className="field w-[100%] sm:w-[48%]]">
            {/* <div className="label">Total initial cost</div> */}
            <LabelWithTooltip label="Total initial cost" tooltip="The total cost to initiate the project" />
            <input
              disabled={!isEditMode.valuation}
              value={formatNumberWithCommas(String(details.valuation.totalInitialCost))}
              type="text"
              name="totalInitialCost"
              onChange={(evt) => handleChangeDetails(evt, "valuation")}
              placeholder="Enter Total Initial Cost in USD"
              className={`${isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
            />
          </div>
          <div className="field w-[100%] sm:w-[48%]">
            {/* <div className="label">Operation lifetime</div> */}
            <LabelWithTooltip label="Operation lifetime" tooltip="The expected duration of the project's operation" />
            <input
              disabled={!isEditMode.valuation}
              value={formatNumberWithCommas(String(details.valuation.operationLifetime))}
              type="text"
              name="operationLifetime"
              onChange={(evt) => handleChangeDetails(evt, "valuation")}
              placeholder="Enter the operational lifetime of your asset"
              className={`${isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
            />
          </div>
        </div>
        <div className="field">
          {/* <div className="label">Financing structure</div> */}
          <LabelWithTooltip label="Financing structure" tooltip="Detailed information about how the project is financed" />
          <textarea
            disabled={!isEditMode.valuation}
            value={details.valuation.financingStructure}
            name="financingStructure"
            onChange={(evt) => handleChangeDetails(evt, "valuation")}
            placeholder="Please provide a detailed of the financing structure"
            className={`${
              isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-t-0 border-l-0 border-r-0 border-b border-monochrome-10 !rounded-none"
            } px-0 w-full text-ternary text-sm placeholder-sm !text-[11px]`}
            style={{ resize: isEditMode.valuation ? "vertical" : "none" }}
            rows={isEditMode.valuation ? 4 : 1}
          />
        </div>
        <div className="field">
          {/* <div className="label">Ownership structure</div> */}
          <LabelWithTooltip label="Ownership structure" tooltip="Information about the owner or owning entity of the project" />
          <textarea
            disabled={!isEditMode.valuation}
            value={details.valuation.ownershipStructure}
            name="ownershipStructure"
            onChange={(evt) => handleChangeDetails(evt, "valuation")}
            placeholder="Please provide Information about the owner or owning entity"
            className={`${
              isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-t-0 border-l-0 border-r-0 border-b border-monochrome-10 !rounded-none"
            } px-0 w-full text-ternary text-sm placeholder-sm !text-[11px]`}
            style={{ resize: isEditMode.valuation ? "vertical" : "none" }}
            rows={isEditMode.valuation ? 4 : 1}
          />
        </div>
        <div className="flex flex-wrap items-center justify-between">
          <div className="field w-[100%] sm:w-[48%]">
            {/* <div className="label">Annual operation costs</div> */}
            <LabelWithTooltip label="Annual operation costs" tooltip="The yearly costs associated with running the project" />
            <input
              disabled={!isEditMode.valuation}
              value={formatNumberWithCommas(String(details.valuation.annualOperationCosts))}
              type="text"
              name="annualOperationCosts"
              onChange={(evt) => handleChangeDetails(evt, "valuation")}
              placeholder="Enter the annual operations cost"
              className={`${isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
            />
          </div>
          <div className="field w-[100%] sm:w-[48%]">
            {/* <div className="label">NPV (Net Present Value)</div> */}
            <LabelWithTooltip
              label="NPV (Net Present Value)"
              tooltip="The difference between the present value of cash inflows and outflows over a period of time"
            />
            <input
              disabled={!isEditMode.valuation}
              value={formatNumberWithCommas(String(details.valuation.npv))}
              type="text"
              name="npv"
              onChange={(evt) => handleChangeDetails(evt, "valuation")}
              placeholder="Enter the Net Present Value"
              className={`${isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
            />
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-between">
          <div className="field w-[100%] xl:w-[48%]">
            {/* <div className="label">IRR (Internal Rate of Return)</div> */}
            <LabelWithTooltip label="IRR (Internal Rate of Return)" tooltip="The annual growth rate that an investment is expected to generate" />
            <input
              disabled={!isEditMode.valuation}
              value={formatNumberWithCommas(String(details.valuation.irr))}
              type="text"
              name="irr"
              onChange={(evt) => handleChangeDetails(evt, "valuation")}
              placeholder="Enter the Internal Rate of Return"
              className={`${isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
            />
          </div>
          <div className="field w-[100%] xl:w-[48%]">
            {/* <div className="label">LCOE (Levelized Cost of Energy)</div> */}
            <LabelWithTooltip
              label="LCOE (Levelized Cost of Energy)"
              tooltip="The average total cost of building and operating the power plant per unit of total electricity generated"
            />
            <input
              disabled={!isEditMode.valuation}
              value={formatNumberWithCommas(String(details.valuation.lcoe))}
              type="text"
              name="lcoe"
              onChange={(evt) => handleChangeDetails(evt, "valuation")}
              placeholder="Enter the Levelized Cost of Energy"
              className={`${isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
            />
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-between">
          <div className="field w-[100%] sm:w-[48%]">
            {/* <div className="label">Payback Period</div> */}
            <LabelWithTooltip label="Payback Period" tooltip="The amount of time it takes to recover the cost of an investment" />
            <input
              disabled={!isEditMode.valuation}
              value={formatNumberWithCommas(String(details.valuation.paybackPeriod))}
              type="text"
              name="paybackPeriod"
              onChange={(evt) => handleChangeDetails(evt, "valuation")}
              placeholder="Enter the payback period"
              className={`${isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
            />
          </div>
          <div className="field w-[100%] sm:w-[48%]">
            {/* <div className="label">Capacity Factor</div> */}
            <LabelWithTooltip
              label="Capacity Factor"
              tooltip="The ratio of actual electrical energy output over a given period to the maximum possible electrical energy output over that period"
            />
            <input
              disabled={!isEditMode.valuation}
              value={formatNumberWithCommas(String(details.valuation.capacityFactor))}
              type="text"
              name="capacityFactor"
              onChange={(evt) => handleChangeDetails(evt, "valuation")}
              placeholder="Enter the capacity factor"
              className={`${isEditMode.valuation ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
            />
          </div>
        </div>
        <div className="flex gap-4 justify-end">
          {!isInReviewForListing && (
            <Button primary rounded type="button" classNames="h-10" onClick={() => handleClickCancleOrEdit("valuation")}>
              {isEditMode.valuation ? "Cancel" : "Edit"}
            </Button>
          )}
          {isEditMode.valuation && !isInReviewForListing && (
            <Button secondary rounded classNames={`h-10`} onClick={handleSaveClick}>
              {isUpdating ? <Loader isLoading={true} /> : "Save"}
            </Button>
          )}
        </div>
      </div>
      <ConfirmationModal
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleUpdateDetails}
        message="Are you sure you want to update the valuation details of your project?"
      />
    </>
  );
};

export default ValuationDetail;
