import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import Button from "@/components/UI/Button";
import { ENDPOINT, ROUTES } from "@/constants";
import useAuth from "@/hooks/useAuth";
import { http } from "@/services/http";
import useStore from "@/store/useStore";

const profileFormSchema = z.object({
  firstName: z
    .string()
    .trim()
    .min(1, "First Name is Required")
    .min(3, "First Name Too Short")
    .max(20, "First Name Too Long")
    .regex(/^[a-zA-Z\s]+$/, "First name must only contain letters and spaces"),
  lastName: z
    .string()
    .trim()
    .min(1, "Last Name is Required")
    .max(20, "Last Name too Long")
    .regex(/^[a-zA-Z\s]+$/, "Last name must only contain letters and spaces"),
  email: z.string().trim().email("Invalid email format").max(50, "Email too long"),
  companyName: z.string().trim().min(1, "Company Name is Required"),
});

type FormField = keyof typeof profileFormSchema.shape;

interface IErros {
  firstName?: { _errors: string[] };
  lastName?: { _errors: string[] };
  email?: { _errors: string[] };
  companyName?: { _errors: string[] };
}

const ProfilePage = () => {
  const { userData, companyData } = useStore();
  const { companyId } = useAuth();
  // eslint-disable-next-line
  const [initialDetails, setInitialDetails] = useState({
    firstName: userData.firstName ?? "",
    lastName: userData.lastName ?? "",
    email: userData.email ?? "",
    companyName: companyData?.companyName ?? "",
  });
  const [userDetails, setUserDetails] = useState(initialDetails);
  const [errors, setErrors] = useState<IErros>({});
  const isProfileExisting = userData.isProfileExisting || false;

  useEffect(() => {
    if (isProfileExisting) {
      navigate(ROUTES.DASHBOARD);
    }
    // eslint-disable-next-line
  }, []);

  const navigate = useNavigate();

  const canSubmit = () => {
    const detailsChanged = JSON.stringify(userDetails) !== JSON.stringify(initialDetails);
    return detailsChanged;
  };

  const validateForm = () => {
    const result = profileFormSchema.safeParse(userDetails);
    if (!result.success) {
      setErrors(result.error.format());
      return false;
    }
    setErrors({});
    return true;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    // Validate the individual field
    const fieldName = name as FormField;
    if (profileFormSchema.shape.hasOwnProperty(fieldName)) {
      const fieldSchema = profileFormSchema.shape[fieldName];
      const result = fieldSchema.safeParse(value);
      if (!result.success) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: { _errors: result.error.errors.map((err) => err.message) },
        }));
      } else {
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors[fieldName];
          return newErrors;
        });
      }
    }
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (validateForm()) {
      const authToken = localStorage.getItem("authToken");
      Promise.all([
        http.patch(`${ENDPOINT.USER}/${userData?._id}`, userDetails, {
          headers: { Authorization: `Bearer ${authToken}` },
        }),
        http.patch(`${ENDPOINT.GET_COMPANY}/${companyId}`, userDetails),
      ])
        .then(() => {
          // toast.success("User details updated successfully.");
          navigate(ROUTES.DASHBOARD);
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.message === "Email already taken") {
            setErrors({ email: { _errors: ["Email already taken"] } });
          } else {
            toast.error(error.data.message);
            console.error(error);
          }
        });
    }
  };

  return (
    <div className="flex flex-col items-center justify-center gap-4 h-[calc(100vh-110px)]">
      <div className="bg-white rounded-lg p-8 w-full md:w-[50%]">
        <h3 className="page-header pb-8">Let’s first set up your profile</h3>
        <form onSubmit={handleSubmit} className="w-full flex flex-col gap-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-x-2">
            <div className="flex flex-col gap-2">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                className="border border-monochrome-10 rounded px-2"
                value={userDetails.firstName}
                disabled={isProfileExisting}
                onChange={handleChange}
              />
              {errors?.firstName && <p className=" text-red-700 error">{errors.firstName._errors[0]}</p>}
            </div>

            <div className="flex flex-col gap-2">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                className="border border-monochrome-10 rounded px-2"
                value={userDetails.lastName}
                disabled={isProfileExisting}
                onChange={handleChange}
              />
              {errors?.lastName && <p className=" text-red-700 error">{errors.lastName._errors[0]}</p>}
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              className="border border-monochrome-10 rounded px-2"
              value={userDetails.email}
              onChange={handleChange}
              disabled
            />
            {errors?.email && <p className=" text-red-700 error">{errors.email._errors[0]}</p>}
          </div>

          <div className="flex flex-col gap-2">
            <label htmlFor="companyName">Company Name</label>
            <input
              type="text"
              name="companyName"
              id="companyName"
              className="border border-monochrome-10 rounded px-2"
              value={userDetails.companyName}
              disabled={isProfileExisting}
              onChange={handleChange}
            />
            {errors?.companyName && <p className=" text-red-700 error">{errors.companyName._errors[0]}</p>}
          </div>

          <Button type="submit" disabled={!canSubmit() || isProfileExisting} secondary rounded>
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ProfilePage;
