import IcBuy from "@/assets/ic_buy";
import IcSell from "@/assets/ic_sell";
import IcCross from "@/assets/Icons/ic_cross";
import IcDropdownChecked from "@/assets/Icons/ic_dropdown_checked";
import IcPending from "@/assets/Icons/ic_pending";
import { ITransaction } from "@/types";
import { formatNumberWithCommas } from "@/utils/commanFunctions";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React from "react";

dayjs.extend(customParseFormat);

interface TransactionItemProps {
  item: ITransaction;
  onClick: () => void;
  isSelected: boolean;
  uniqueId: string;
  currentUserId: string; // Current user's ID or wallet address
  currentWalletAddress: string;
}

const TransactionItem: React.FC<TransactionItemProps> = ({
  item,
  onClick,
  isSelected,
  uniqueId,
  currentUserId,
  currentWalletAddress
}) => {
  const parsedDate = dayjs(item.updatedAt);

  // Determine if the transaction is on-chain or off-chain
  const isOnChain = item.transactionMethod === "ON_CHAIN";

  // Check if the transaction is incoming or outgoing for the current user
  const isIncomingTransaction = () => {
    if (isOnChain) {
      return item.toAddress?.toLowerCase() === currentWalletAddress.toLowerCase();
    } else {
      return item.toUserId?._id === currentUserId;
    }
  };

  const isOutgoingTransaction = () => {
    if (isOnChain) {
      return item.fromAddress?.toLowerCase() === currentWalletAddress.toLowerCase();
    } else {
      return item.fromUserId?._id === currentUserId;
    }
  };

  // Set transaction type and color based on direction and amount
  const transactionType = isIncomingTransaction() ? "Buy" : "Sell";
  // eslint-disable-next-line
  const isAmountIncoming = item.paymentTokensTransferred > 0; // Positive amounts indicate incoming transactions
  const transactionColor = isIncomingTransaction()
    ? "text-green-500"
    : isOutgoingTransaction()
    ? "text-red-600"
    : "text-gray-400";
  const transactionSign = isIncomingTransaction() ? "+" : isOutgoingTransaction() ? "-" : "";

  // Determine the transaction status icon
  let statusIcon;
  switch (item.status) {
    case "FAILED":
      statusIcon = <IcCross className="!text-red-500 transform scale-110" />;
      break;
    case "COMPLETED":
      statusIcon = <IcDropdownChecked className="h-[16px] w-[16px]" />;
      break;
    case "PENDING":
      statusIcon = <IcPending className="!text-yellow-300" />;
      break;
    default:
      statusIcon = null;
  }

  // Determine the transaction name based on its purpose
  let transactionName;
  switch (item.transactionPurpose) {
    case "SECURITY_TOKEN_PURCHASE":
      transactionName = "Security Token Purchase";
      break;
    case "DIVIDEND_PAYMENT":
      transactionName = "Dividend Payment";
      break;
    case "SECURITY_TOKEN_SALE":
      transactionName = "Sell Token";
      break;
    case "SECURITY_TOKEN_TRANSFER":
      transactionName = "Security Token Transfer";
      break;
    case "PAYMENT_TOKEN_TOP_UP":
      transactionName = "Top Up";
      break;
    case "PAYMENT_TOKEN_WITHDRAW":
      transactionName = "Withdraw";
      break;
    default:
      transactionName = null;
  }

  return (
    <div
      className={`text-[14px] py-[14px] px-4 grid grid-cols-5 text-monochrome-60 border-b cursor-pointer border-monochrome-10 ${isSelected ? "bg-gray-100" : "bg-white"}`}
      onClick={onClick}
      data-id={uniqueId}
    >
      <span>{parsedDate.format("MMM D, YYYY")}</span>
      <span className="flex items-center">
        {transactionType === "Buy" ? <IcBuy /> : <IcSell />}
        <span className="ml-2">{transactionName}</span>
      </span>
      <span className="my-auto">{formatNumberWithCommas(item.paymentTokenName) || 'USD'}</span>
      <span>{parsedDate.format("hh:mm A")}</span>
      <span className={`flex gap-2 items-center ${transactionColor}`}>
        {transactionSign}
        <span>${Math.abs(item.paymentTokensTransferred).toFixed(2)}</span>
        {statusIcon}
      </span>
    </div>
  );
};

export default TransactionItem;
