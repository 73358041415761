import { IcFolder } from "@/assets/Icons";
import IcCross from "@/assets/Icons/ic_cross";
import Button from "@/components/UI/Button";
import { formatBytes } from "@/constants/extensions";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { FaInfoCircle } from "react-icons/fa";
import { z } from "zod";

type Props = {
    currentStep: number,
    setCurrentStep: (value: number) => void;
    setFormDetails: (value: any) => void;
    formDetails: any,
    handleSubmit: () => void
}

const validationSchema = z.object({
    dataRoomLink: z.string().optional(),
    files: z.array(
        z.object({
            name: z.string(),
            size: z.number(),
            type: z.string(),
            lastModified: z.number(),
        })
    ).optional()
});


const CustomTooltip = ({ content }: { content: string }) => {
    const [isVisible, setIsVisible] = useState(false);
    
    console.log("Content: ",content)
    return (
      <div className="relative inline-block">
        <FaInfoCircle
          className="text-gray-400 cursor-pointer ml-1"
          onMouseEnter={() => setIsVisible(true)}
          onMouseLeave={() => setIsVisible(false)}
        />
        
        {isVisible && (
          <div className="absolute z-10 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700 bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-48">
            {content}
            <div className="tooltip-arrow" data-popper-arrow></div>
          </div>
        )}
      </div>
    );
  };
  
  const LabelWithTooltip = ({ label, tooltip }: { label: string; tooltip: string }) => (
  
    <div className="label flex items-center">
      {label}
      <CustomTooltip content={tooltip} />
    </div>
  );
  

const SupportingDocuments = ({ currentStep, setCurrentStep, setFormDetails, formDetails }: Props) => {
    const [errors, setErrors] = useState<any>({});
    const [selectedFiles, setSelectedfiles] = useState<any>(formDetails.files || []);
    const [dataRoomLink, setDataRoomLink] = useState(formDetails.dataRoomLink || '');
    const inputPicker = useRef<HTMLInputElement | null>(null);

    const openFilePicker = () => {
        if (inputPicker.current) {
            inputPicker.current.click();
        }
    };

    const handleImageChange = async (e: React.FormEvent<HTMLInputElement>) => {
        const files = inputPicker.current?.files;
        if (files) {
            let filesArray: any = [];
            Array.from(files).forEach(file => {
                filesArray.push(file);
            });

            if (selectedFiles.length + filesArray.length > 10) {
                toast.error("You can only upload a maximum of 10 documents.");
            } else {
                setSelectedfiles([...selectedFiles, ...filesArray]);
            }
        }
    };

    const removeFile = (index: number) => {
        setSelectedfiles(selectedFiles.filter((_: any, i: number) => i !== index));
    };

    useEffect(() => {
        setFormDetails((prev: any) => ({ ...prev, files: selectedFiles, dataRoomLink }));
        // eslint-disable-next-line
    }, [selectedFiles, dataRoomLink]);

    const handleChange = (e: any) => {
        const { name, value } = e.target;

        if (name === "dataRoomLink") {
            // Automatically format links with a new line after space
            const links = value.split(' ');
            setDataRoomLink(links.join('\n'));
        }

        setFormDetails((prevFormData: any) => ({
            ...prevFormData,
            [name]: value,
        }));

        const fieldValidationResult = validationSchema.pick({ [name]: true }).safeParse({ [name]: value });
        if (!fieldValidationResult.success) {
            setErrors((prevErrors: any) => ({
                ...prevErrors,
                [name]: fieldValidationResult.error.flatten().fieldErrors[name][0],
            }));
        } else {
            setErrors((prevErrors: any) => {
                const newErrors = { ...prevErrors } as any;
                delete newErrors[name];
                return newErrors;
            });
        }
    };

    const handleNext = (e: React.SyntheticEvent) => {
        e.preventDefault();

        const result = validationSchema.safeParse(formDetails);

        if (!result.success) {
            setErrors(result.error.flatten().fieldErrors);
            toast.error("Please correct the errors before submitting.");
            return;
        } else {
        setCurrentStep(currentStep + 1);
        }
    };

    return (
        <>
            <h4>(Optional) Please share your data room or any other resources </h4>
            <p className='text-monochrome-30 mt-2 body-regular'>This helps us reduce the processing time</p>
            <form onSubmit={handleNext} className="body-small-regular">
                <div className="grid grid-cols-1 gap-2 mt-6">
                    {/* <label>Additional Links</label> */}
                    <LabelWithTooltip label="Additional Links" tooltip="Add in any additional links you feel is to be reviewed by penomo" />
                    <textarea placeholder="Add relevant links" name="dataRoomLink" className="border border-monochrome-20 rounded-lg" rows={6} value={dataRoomLink} onChange={(e) => handleChange(e)}></textarea>
                     {errors.dataRoomLink && <p className="text-red-500 error">{errors.dataRoomLink}</p>}
                </div>

                <div className="flex flex-column gap-6 mt-6">
                    <div className="flex flex-column">
                        {/* <label>Upload Files</label> */}
                        <LabelWithTooltip label="Upload Files" tooltip="Upload any files you feel that is relavent for fund raising" />
                        <input type="file" className="hidden" ref={inputPicker} onInput={handleImageChange} multiple />
                        <Button primary rounded classNames="bg-transparent py-3 w-[150px]" onClick={openFilePicker}>
                            <IcFolder />
                            Upload file
                        </Button>
                        <p className="text-monochrome-30 mt-2">Upload a maximum of 10 documents</p>
                        {errors.files && <p className="text-red-500 error mt-2">{errors.files}</p>}
                    </div>
                    <div className="flex flex-column">
                        {/* <label>Attached Files</label> */}
                        <LabelWithTooltip label="Attached Files" tooltip="These are a list of files you have uploaded" />
                        <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-4">
                            {
                                selectedFiles.map((item: any, index: number) => (
                                    <div key={'file-' + index} className="border border-monochrome-20 rounded-lg flex gap-2 flex-column px-4 py-2 pr-8 relative">
                                        <span>{item.name}</span>
                                        <span className="text-monochrome-30">{formatBytes(item.size)}</span>
                                        <div className="bg-monochrome-5 cursor-pointer w-[24px] aspect-square rounded absolute top-2 right-2 flex items-center justify-center">
                                            <IcCross onClick={() => removeFile(index)} />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className='flex gap-4'>
                    <Button primary rounded classNames="mt-6" onClick={() => { setCurrentStep(currentStep - 1) }}>Back</Button>
                    <Button secondary rounded type="submit" classNames="mt-6">Next</Button>
                </div>
            </form>
        </>
    );
}

export default SupportingDocuments;
